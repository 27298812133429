import React from 'react'
import './sidebar.scss'
import defaultBanner from '../../../style/images/bannerImage.jpg'
import fishkaBanner from '../../../style/images/Banner/Fishka290.png'
import banner1 from '../../../style/images/Banner/banner1.jpg'
import banner2 from '../../../style/images/Banner/banner2.jpg'
import banner3 from '../../../style/images/Banner/banner3.jpg'
import banner4 from '../../../style/images/Banner/banner4.jpg'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { openFishka } from '../../../redux/fishka/fishka-actions';
import { useDispatch } from "react-redux"
const bannerSet = [fishkaBanner, banner1, banner2, banner3, banner4]

const Sidebar = () => {
  const dispatch = useDispatch()

  return (
    <div className='sidebarContainer'>
      <Splide
        options={{
          type: 'loop',
          gap: '1em',
          autoplay: true,
          pauseOnHover: true,
          resetProgress: false,
          arrows: false,
          direction: 'ttb',
          focus: 'center',
          height: "75vh",
          updateOnMove: true,
          perPage: 4,
          autoHeight: true,
          perMove: 1,
          padding: {
            top: '0.5rem',
            bottom: '0.5rem',
          },
          keyboard: false,
          classes: {
            prev: 'splide__arrow--prev your-class-prev',
            next: 'splide__arrow--next your-class-next',
            page: 'splide__pagination__page your-class-page',
            pagination: "splide__pagination class-pagination"
          }
        }}
      >
        {bannerSet.map((slide, i) => (
          <SplideSlide key={i}>
            <div className='imageBox'>
              {i === 0 && (
                <a href="https://myfishka.com/partner/094" target='_blank'>
                  <img
                    // onClick={i === 0 ? () => dispatch(openFishka()) : null}
                    className={`bannerImage ${i === 0 ? 'normal' : ''}`}
                    src={slide}
                    alt='banner-image'
                  />
                </a>
              )}
              {i !== 0 && (
                <img
                  // onClick={i === 0 ? () => dispatch(openFishka()) : null}
                  className={`bannerImage moreWidth`}
                  src={slide}
                  alt='banner-image'
                />
              )}
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  )
}

export default Sidebar