import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import App from '../App'
import { routers } from '../config'
import { getCurrentUser, login } from '../redux/auth/auth-operations'
import AllRoutes from '../routes/routes'
import Spinner from '../views/components/ui/Spinner/Spinner'
import Auth from '../views/pages/Auth/Auth'
import CardInfo from '../views/pages/CardInfo/CardInfo'
import Home from '../views/pages/Home/Home'
import firebase from 'firebase/app'
import logo from '../style/icons/logo.svg'
import LoadingPlaceholder from '../views/components/LoadingPlaceholder/LoadingPlaceholder'
import { getIsRegisterCardNeeded, getIsToken, getIsUser, getIsUserLoading, getUserMainCardSelector } from '../redux/auth/auth-selectors'
import RegistrationAuthPage from '../views/pages/RegistrationAuthPage/RegistrationAuthPage'
import useUpdateEffect from '../hooks/useUpdateEffect'
import { Redirect } from 'react-router'

function Authorization(Component) {
  const firebaseConfig = {
      apiKey: "AIzaSyB6_Dpzsy22DATLAiUwoy2HMFeXQomBRdM",
      authDomain: "dobrodoc-392f5.firebaseapp.com",
      databaseURL: "https://dobrodoc-392f5.firebaseio.com",
      projectId: "dobrodoc-392f5",
      storageBucket: "dobrodoc-392f5.appspot.com",
      messagingSenderId: "895113743631",
      appId: "1:895113743631:web:96b9d0283b5172b396c27a",
      measurementId: "G-W96KLKL1Z8",
    };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app();
  }

  return function (props) {
    const path1 = localStorage.getItem('path1')
    const { isRegisterNeeded, isToken, isUser, getCurrentUser, isUserLoading } = props;
    const pathToHome = isUser && !isUserLoading;
    const pathToPreloader = isUserLoading;
    const readyToGetUser = isToken && !isUser && !isUserLoading && !isRegisterNeeded;

    readyToGetUser && getCurrentUser()

    if (pathToHome) {
      console.log(path1);
      return (
        <Component>
          <Home currentPath={path1} {...props}/>
        </Component>
      )
    }

    if (pathToPreloader) {
      return <LoadingPlaceholder />
    }

    return (
      <RegistrationAuthPage
        redirectTo={(isToken && isRegisterNeeded) ? '/registration' : '/login'}
        // redirectTo={'/registration'}
        {...props}
      />
    )
  }
}

const mapDispatchToProps = {
  login,
  getCurrentUser,
}
const mapStateToProps = (state) => ({
  isUserLoading: getIsUserLoading(state),
  isUser: getIsUser(state),
  isToken: getIsToken(state),
  isRegisterNeeded: getIsRegisterCardNeeded(state)
})

const composedAuthHOC = compose(
  connect(mapStateToProps, mapDispatchToProps), Authorization
)

export default composedAuthHOC;