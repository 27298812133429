import React from 'react'
import {routers} from '../config'
import { Redirect, Route, Switch } from 'react-router'
import LoadingPlaceholder from '../views/components/LoadingPlaceholder/LoadingPlaceholder'
import ChatModeObserver from '../chatHoc/ChatModeObserver';
import { LoadingComponent } from '../views/pages/ConsultationBooking/ConsultationBooking';
const Clinic = React.lazy(() => import('../views/pages/Clinic/Clinic'));
const ConsultationBooking = React.lazy(() => import('../views/pages/ConsultationBooking/ConsultationBooking'));
const MedicalCardsPage = React.lazy(() => import('../views/pages/MedicalCardsPage/MedicalCardsPage'));
const Notifications = React.lazy(() => import('../views/pages/Notifications/Notifications'));
const CardInfo = React.lazy(() => import('../views/pages/CardInfo/CardInfo'));
const NewCardPage = React.lazy(() => import('../views/pages/NewCardPage/NewCardPage'));
const AnalysesAndDrugsBooking = React.lazy(() => import('../views/pages/AnalysesBooking/AnalysesAndDrugsBooking'));
// const ChatModeObserver = React.lazy(() => import('../chatHoc/ChatModeObserver'));

const AllConsultations = React.lazy(() => import('../views/pages/AllConsultations/AllConsultations'));
const Subscriptions = React.lazy(() => import('../views/pages/Subscriptions/Subscriptions'));
const SuccessPaymentPage = React.lazy(() => import('../views/pages/SuccessPaymentPage/SuccessPaymentPage'));
const DoctorsPage = React.lazy(() => import('../views/pages/DoctorsPage/DoctorsPage'));

const routesList = [
  { component: CardInfo, path: routers.cardInfo},
  { component: Clinic, path: routers.clinic },
  { component: AllConsultations, path: routers.consultations },
  { component: MedicalCardsPage, path: routers.medicalCards },
  { component: Notifications, path: routers.notifications },
  { component: ConsultationBooking, path: routers.consultationBooking },
  { component: NewCardPage, path: routers.newCard },
  { component: AnalysesAndDrugsBooking, path: routers.analysesBooking },
  { component: ChatModeObserver, path: routers.supportChat },
  { component: ChatModeObserver, path: routers.doctorChat },
  { component: Subscriptions, path: routers.subscriptions },
  { component: AnalysesAndDrugsBooking, path: routers.drugs },
  { component: SuccessPaymentPage, path: routers.successPayment },
  { component: DoctorsPage, path: routers.doctors }
]

const AllRoutes = (props) => {
  const currentPath = localStorage.getItem('path1')
  const defaultPath = currentPath != '/' && (currentPath || '/clinic')
  
  return (
    <React.Suspense fallback={<div></div>}>
      <div className='mainContentContainer'>
        <Switch>
          {/*  */}
          <Route
            path={routers.clinic}
            exact
            render={() => <>
              <Clinic isSearching={props.isSearching} />
            </>}
          />
          <Route
            path={routers.supportChat}
            exact
            render={() => <>
              <ChatModeObserver supportMode />
            </>}
          />
          <Route
            path={routers.subscriptions}
            exact
            render={() => <>
              <Subscriptions />
            </>}
          />
          <Route
            path={routers.consultations}
            exact
            render={() => <>
              <AllConsultations {...props} />
            </>}
          />
          <Route
            path={routers.doctorChat}
            exact
            render={() => <>
              <ChatModeObserver />
            </>}
          />
          <Route
            path={routers.medicalCards}
            exact
            render={() => <>
              <MedicalCardsPage {...props} />
            </>}
          />
          <Route
            path={routers.notifications}
            exact
            render={() => <>
              <Notifications {...props} />
            </>}
          />
          <Route
            path={routers.consultationBooking}
            exact
            render={() => <>
              <ConsultationBooking {...props} />
            </>}
          />
          <Route
            path={routers.cardInfo}
            exact
            render={() => <>
              <CardInfo {...props} />
            </>}
          />
          <Route
            path={routers.newCard}
            exact
            render={() => <>
              <NewCardPage {...props} />
            </>}
          />
          {/* <Route
          path={routers.analysesBooking}
          exact
          render={() => <>
            <AnalysesAndDrugsBooking {...props} />
          </>}
        /> */}
          <Route
            path={routers.drugs}
            exact
            render={() => <>
              <AnalysesAndDrugsBooking {...props} />
            </>}
          />
          {/* <Route
          path={routers.success}
          exact
          render={() => <>
            <SuccessPayment {...props} />
          </>}
        /> */}
          <Route
            path={routers.successConsultation}
            exact
            render={() => <>
              <SuccessPaymentPage consultation {...props} />
            </>}
          />
          <Route
            path={routers.successSubscription}
            exact
            render={() => <>
              <SuccessPaymentPage subscription {...props} />
            </>}
          />
          <Route
            path={routers.doctors}
            exact
            render={() => <>
              <DoctorsPage {...props} />
            </>}
          />
          <Route path='/'>
            <Redirect to={defaultPath || '/clinic'}/>
          </Route>
        </Switch>
      </div>
    </React.Suspense>
  )
}


export default AllRoutes