import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../ui/Button/Button';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import './imageEditor.scss'
import { IconButton, makeStyles } from '@material-ui/core';
import { useCallback } from 'react';
import {useDropzone} from 'react-dropzone'
import AddAPhotoSharpIcon from '@material-ui/icons/AddAPhotoSharp';
import { getCroppedImg } from '../../../utils/imageConverter';
import { useEffect } from 'react';
import { LoadingComponent } from '../../pages/ConsultationBooking/ConsultationBooking';
import { useContext } from 'react';
import { CanvasContext } from '../../pages/NewCardPage/CanvasContext';

const useModalStyles = makeStyles({
  button: {
    position: "absolute",
    top: "2px",
    right: "2px"
  },
  buttonBlack: {
    position: "absolute",
    top: "5px",
    right: "5px",
    color: "black",
  }
})

const ImageEditor = ({
  handleSendRequest,
  photoLoading = false,
  cardId = null,
  setImageModalOpen,
  setEditedImage = null,
  outerDrawing = false,
  setOuterBlob,
}) => {
  const [dropCompleted, setDropCompleted] = useState(false)
  const [ObjectURL, setObjectURL] = useState(null)
  const [completedCrop, setCompletedCrop] = useState(null)
  const [blob, setBlob] = useState(null)
  const [file, setFile] = useState(null)
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const canvasContextRef = useContext(CanvasContext)
  const [crop, setCrop] = useState({
    aspect: 1,
    unit: '%',
    height: 60,
    x: 25,
    y: 25,
  });
  const { t } = useTranslation()
  const classes = useModalStyles()

  const onDrop = useCallback(acceptedFiles => {
    if (!acceptedFiles.length) return
    setDropCompleted(true)
    setFile(acceptedFiles[0])
    setObjectURL(URL.createObjectURL(acceptedFiles[0]))
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png'
  })

  const handleChange = useCallback((newCrop) => {
    if (newCrop.height < 100) {
      setCrop(crop)
      return
    }
    setCrop(newCrop)
  }, [])

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!setOuterBlob) return
    setOuterBlob(blob)
  }, [blob])

  useEffect(() => (async () => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    let canvas = null
    // if(no)
    if (outerDrawing) {
      canvas = canvasContextRef 
    } else {
      canvas = previewCanvasRef.current;
    }
    // const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    
    const blob = await cnvToBlob(canvas, 'image')
    setBlob(blob)
  })(), [completedCrop]);

  const cnvToBlob = (canvas, fileName) => {
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          console.log(blob);
          blob.name = fileName;
          resolve(blob);
        },
        "image/jpeg",
        0.5
      );
    });
  }

  const handleAccept = () => {
    if (handleSendRequest) {
      handleSendRequest(blob)
      return
    }
    setEditedImage(blob)
    setImageModalOpen(false)
  }

  return (
    <div className='imageCropContainer'>
      {!dropCompleted && (
        <div
          className={`dropContainer ${isDragActive ? 'activeDrag' : ''}`}
          {...getRootProps()}
        >
          <IconButton
            className={classes.buttonBlack}
            variant='contained'
            color='primary'
            onClick={(e) => {
              e.stopPropagation()
              setImageModalOpen(false)
            }}
            title={t("back")}
          >
            <CloseTwoToneIcon />
          </IconButton>
          <div className='dropTextBox'>
            <AddAPhotoSharpIcon style={{ width: "50px", height: "50px", marginBottom: "20px", color: "#3287cd" }} />
            <Button
              type='button'
              className='btn'
              primary
              text={t("choosePhoto")}
            />
            <div className="dropText">{t("orDragNDropHere")}</div>
          </div>
          <input
            {...getInputProps()}
            style={{ border: "none", outline: 'none', width: '0px', height: "0px" }}
            onKeyDown={(e) => { }}
            onClick={(e) => { }}
            onKeyDown={(e) => { }}
            onKeyPress={(e) => { }}
            onKeyUp={(e) => { }}
          />
        </div>
      )}
      {dropCompleted && ObjectURL && (
        <>
          <div className='cropTitle'>Выберите нужную область</div>
          <IconButton
            className={classes.button}
            variant='contained'
            color='primary'
            onClick={() => setImageModalOpen(false)}
            title={t("back")}
          >
            <CloseTwoToneIcon />
          </IconButton>
          <ReactCrop
            src={ObjectURL}
            crop={crop}
            onImageLoaded={onLoad}
            onChange={handleChange}
            onComplete={(c) => setCompletedCrop(c)}
            circularCrop
            style={{
              borderRadius: '15px',
              padding: "2px",
              backgroundColor: 'transparent',
              maxHeight: "322px",
              height: "auto"
            }}
            imageStyle={{
              minHeight: "250px",
              maxHeight: "320px",
              borderRadius: '15px',
            }}
          />
            <canvas
              ref={previewCanvasRef}
              style={{ width: "0px", height: "0px"}}
            />
          <div className='cropButtons'>
            <div className="loadingBox">
              <LoadingComponent loading={photoLoading} width={40} height={40}/>
            </div>
            <Button
              type='button'
              className='btn'
              disabled={photoLoading}
              primary
              onClick={handleAccept}
              text={t("Confirm")}
            />
            <Button
              type='button'
              className='btn'
              danger
              onClick={() => setImageModalOpen(false)}
              text={t("cancel")}
            />
          </div>
        </>
      )}
    </div>
  );
}


export default ImageEditor;
