import { setHeader, TEST_API } from ".."
import axios from 'axios'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getSubscriptionInfo: (cardId) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/subscriptions/cards/${cardId}?filter[onlyBooked]=true`,
      headers: setHeader(),
    }
    return axios(config).then(res => res.data)
  },
  getSubscriptionList: (cardId, booked = false) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/subscriptions/cards/${cardId}${booked ? '?filter[onlyBooked]=true' : ''}`,
      headers: setHeader(),
    }
    return axios(config).then(res => res.data)
  },
  bookSubscription: (subId, cardId, body, bonuses) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/subscriptions/${subId}/cards/${cardId}`,
      headers: {
        ...setHeader(),
        "payment-success-url": `${window.location.origin}/subscriptions/payment/success`,
      },
      data: body || bonuses || {},
    }
    return axios(config).then(res => res.data)
  }
}