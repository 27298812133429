import React, {useState} from 'react'; 
import { useEffect } from 'react';
import { useRef } from 'react';
import "./simpleInput.scss";

const SimpleInput = ({
  AuthFormat,
  placeholder,
  value,
  className,
  errorMessage,
  errorRequire,
  label,
  validDate,
  required,
  ...rest
}) => {
  const [isError, setError] = useState(false);
  const [isRequire, setIsRequire] = useState(false);
  const [isFocused, setIsFocused] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const inputRef = useRef()
  
  React.useEffect(() => {
    if (rest.defaultValue) {
      setInputValue(rest.defaultValue)
    }
  }, [rest.defaultValue])

  const handleBlur = (evt) => {
    const {value, pattern, required, type, min, max} = evt.target;
    
    if (!rest.required) {
      setIsFocused(false)
    }

    if (required && !value.trim()) {
      setIsRequire(true);
    }

    if (pattern && !(new RegExp(pattern).test(value))) {
      setError(true);
    }

    if (type === 'number') {
      setError(err => typeof Number(value) === 'number' &&  min < value && max > value ? false: true)
    }
  }

  const handleFocus = (evt) => {
    if (!rest.required) {
      setIsFocused(true)
    }
    if (isError){
      setError(false);
    }

    if (isRequire) {
      setIsRequire(false)
    }
  }

  const handleInputChange = (event) => {
    const { value } = event.target;
    if (rest.noTabs) {
      setInputValue(value.trim())
    } else {
      setInputValue(value)
    }
  }

  const errorClassName = isError ? 'errorMessage show' : 'errorMessage';
  const requireClasseName = isRequire ? 'errorRequire show' : 'errorRequire';
  const inputId =`input${rest.name}`;

  return (
    <div className="formField">  
      <div className={`${AuthFormat ? 'authFieldSimpleInput' : 'formFieldSimpleInput'}`}>
        <label className={requireClasseName} htmlFor={inputId}>{errorRequire}</label>
        <label className={errorClassName} htmlFor={inputId}>{errorMessage}</label>
        {/* {rest.required && (
          <div className='withStar'></div>
        )} */}
        <input
          ref={inputRef}
          onFocus={handleFocus}
          value={inputValue}
          onChange={handleInputChange}
          className={`simpleInput ${rest.required ? 'withStart' : ''}`}
          onBlur={handleBlur}
          id={inputId}
          placeholder={required ? `${placeholder}*` : placeholder}
          required={required}
          {...rest}
          autoComplete="off"
        />
        {isFocused && !rest.required && (
          <label className="inputLabel" htmlFor={inputId}>{label}</label>
        )}
        {rest.required && (
          <label className="inputLabel" htmlFor={inputId}>{label}</label>
        )}
      </div>
    </div>
  );
};

export default SimpleInput;
