import {
  wsSupportConnect,
  wsSupportConnected,
  wsSupportDisconnected,
  addMessage,
  setChatMemberCount,
  wsCallConnection,
  setCallInfo,
  changeChatMode,
  setVideoStatus,
  setAudioStatus,
  setModalCallInfo,
  setConnectedToPeer,
  isBackgroundCallNeeded,
  resetStatus,
  setConnectionState,
} from "../chat/chat-actions";
import io from 'socket.io-client'
import chatModes from "../../utils/chatModes";
import { setUnreadedNotificationsCount } from "../notifications/notifications-actions";
import firebase from "firebase/app";
import "firebase/messaging";
import api from "../../api";
import { getNotifications } from "../notifications/notifications-operations";
import { addNotification } from "../popup/popup-actions";
import {v4} from 'uuid'
import { answerOnAnamnez, getAllConsultations, getConsultAnamnesis, getConsultationInfo } from "../consultations/consultations-operations";
import info from "../../utils/info";
import { SOCKET_HOST, SOCKET_HOST_CALL } from "../../views/components/Chat1/connection";
import i18n from "../../i18n";
import moment from "moment"
import { setCurrentAnamnez, setUsedAnamnesis } from "../consultations/consultations-actions";
import { getCurrentDate } from "../../utils/helpers";
import { getIsAnamnezDisabled } from "../consultations/consultations-selectors";
import { getLastAnamnezId } from "../chat/chat-selectors";
import { sendPushToOperator } from "../chat/chat-operations";


const configuration = {
  iceServers: [
    {
      urls: "stun:relay1.gooddoc.fun:80",
      username: "domus",
      credential: "sPy80Hb7o(",
    },
    {
      urls: "turn:relay1.gooddoc.fun:80?transport=udp",
      username: "domus",
      credential: "sPy80Hb7o(",
    },
    {
      urls: "turn:relay1.gooddoc.fun:80?transport=tcp",
      username: "domus",
      credential: "sPy80Hb7o(",
    },
  ],
};

const socketMiddleware = () => {
  let socket = null;
  let callSocket = null;
  let appointmentSocket = null;
  let messaging = null;
  let peerConnection = null;
  let iceCandidates = [];
  let recordedBlobs = [];
  let mediaRecorder = {};
  let optionsMediaRecorder;
  let localStream = null
  let remoteStream = new MediaStream()
  let patientVideoRef = {}
  let doctorVideoRef = {}
  let callType = ''
  let doctorAudioRef = {}
  let prevAudio = null;
  let offerCount = 0;
  let isCallingAgain = false
  let gotRemoteDescription = false
  let currentConsultId = null;
  let socketPool = []
  let disableAnamnezButtonNeeded = false
  let currentSocketMode = null

  const onOpen = store => (event) => {
    store.dispatch(wsSupportConnected(event.target.url));
  };

  const onClose = store => () => {
    store.dispatch(wsSupportDisconnected());
  };

  const onMessage = store => (msg = Object) => {
    if (disableAnamnezButtonNeeded && msg.customParameters.question_id) {
      msg.customParameters.withButton = true
    }
    store.dispatch(addMessage(msg))
    if (!msg.isSentByUser) return
    const lastAnamnez = getLastAnamnezId(store.getState())
    if (currentConsultId && lastAnamnez) {
      const requestBody = {anamnesis: {[lastAnamnez]: msg.message}}
      store.dispatch(answerOnAnamnez(currentConsultId, requestBody))
    }
    if (msg.isSentByUser) {
      if (currentSocketMode === "support") {
        store.dispatch(sendPushToOperator({message: msg.message}))
      }
    }
  };

  const setNotificationsCountAlert = (countFromSaved, store) => {
    localStorage.setItem('unreadedCount', countFromSaved + 1)
    store.dispatch(setUnreadedNotificationsCount(countFromSaved + 1))
  }

  const onNotification = store => (msg = {}) =>  {
    if (!msg?.data?.pushCategory) return
    const currentLocation = window.location.pathname;
    const countFromSaved = +localStorage.getItem('unreadedCount') || 0

    if (!currentLocation.includes('notifications')) {
      store.dispatch(getNotifications())
    }

    const { pushCategory, pushType, title, message } = msg.data
    console.log(msg);
    switch (pushCategory) {
      case 'chat':
        switch (pushType) {
          case 'doctor':
            if(currentLocation.includes('appointment')) return
            store.dispatch(addNotification({
              type: "SUCCESS",
              id: v4(),
              message: message || title,
              // message: i18n.t("receivedFromDoctor"),
              isSupport: !!msg.data.isSupport,
              parameters: {
                appointmentId: msg.data.appointmentId,
                url: `/appointment/${msg.data.appointmentId}`
              }
            }))
            setNotificationsCountAlert(countFromSaved, store)
            return
          case 'operator':
            if(currentLocation.includes('support')) return
            store.dispatch(addNotification({
              type: "SUCCESS",
              id: v4(),
              message: message || title,
              isSupport: !!msg.data.isSupport,
              parameters: {
                redirectUrl: '/support'
              }
            }))
            setNotificationsCountAlert(countFromSaved, store)
            return
        }
        break;
      case 'call':
        switch (pushType) {
          case 'incoming':
            // if(!window.location.pathname.includes(""))
            if (callSocket) {
              callSocket.close();
              callSocket = null;
            }
            store.dispatch(wsCallConnection({
              host: SOCKET_HOST_CALL,
              query: {
                userId: info.getUserId(),
                doctorId: msg.data.doctorId,
                doctorSpecialtyId: msg.data.doctorSpecialtyId,
                token: info.getToken(),
              }
            }))
            if (msg.data.type === "1") {
              callType = "audio"
            }
            if (msg.data.type === "2") {
              callType = "video"
            }
            if (!window.location.pathname.includes("appointment")) {
              store.dispatch(setModalCallInfo({
                ...msg.data
              }))
            }
            store.dispatch(setCallInfo(msg.data))
            store.dispatch(changeChatMode(chatModes.CALL))
            return
          case 'missed':
            store.dispatch(addNotification({
              type: "SUCCESS",
              id: v4(),
              message: message || title,
              isSupport: !!msg.data.isSupport,
              noClick: 'true',
            }))
            return
        }
        break;
      case 'appointment':
        store.dispatch(getAllConsultations())
        switch (pushType) {
          case 'create':
            store.dispatch(addNotification({
              type: "SUCCESS",
              id: v4(),
              message: message || title,
              isSupport: !!msg.data.isSupport,
              parameters: {
                appointmentId: msg.data.appointmentId,
                url: `/appointment/${msg.data.appointmentId }`
              }
            }))
            setNotificationsCountAlert(countFromSaved, store)
            return
          case 'start':
            store.dispatch(addNotification({
              type: "SUCCESS",
              id: v4(),
              message: message || title,
              isSupport: !!msg.data.isSupport,
              parameters: {
                appointmentId: msg.data.appointmentId,
                url: `/appointment/${msg.data.appointmentId }`
              }
            }))
            setNotificationsCountAlert(countFromSaved, store)
            return
          case 'cancel':
            store.dispatch(addNotification({
              type: "SUCCESS",
              id: v4(),
              message: message || title,
              isSupport: !!msg.data.isSupport,
              parameters: {
                redirectUrl: '/consultations'
              }
            }))
            setNotificationsCountAlert(countFromSaved, store)
            return
          case 'done':
            store.dispatch(addNotification({
              type: "SUCCESS",
              id: v4(),
              message: i18n.t("endConsult") + (message || title),
              isSupport: !!msg.data.isSupport,
              parameters: {
                redirectUrl: '/consultations'
              }
            }))
            setNotificationsCountAlert(countFromSaved, store)
            return
        }
        break;
      default: return
    }
  }

  const onJoinRoom = store => (data = Number) => {
    store.dispatch(setChatMemberCount(data))
  };

  const onLeaveRoom = store => (data = Number) => {
    store.dispatch(setChatMemberCount(data))
    stopCall(store)
  };

  const onCallMessage = store => async (msg) => {
    console.log(msg);
    switch (msg.type) {
      case 'call':
        // callType = msg.customParameters.call_type
        // store.dispatch(setCallInfo(msg.customParameters))
        // store.dispatch(changeChatMode(chatModes.CALL))
        break;
      case 'reject':
        store.dispatch(setModalCallInfo(null))
        store.dispatch(changeChatMode(chatModes.CHAT))
        break;
      case 'offer':
        console.log('offer received');
        // if(offerCount) return
        asyncHandleReceiveCall(store, msg.offer)
        // offerCount = 1
        break;
      case 'candidate':
        handleCandidateMessage(msg)
        break;
      case 'ended':
        stopCall(store)
        // handleCandidateMessage(msg)
        break;
      default:
        break;
    }
  };

  const createPeer = (store) => {
    const peer = new RTCPeerConnection(configuration)
    peer.addEventListener('icecandidate', onIceCandidate)
    peer.addEventListener('track', onAddTrack)
    peer.addEventListener('connectionstatechange', checkConnectionEvent(store))
    return peer
  }

  const closePeer = () => {
    isCallingAgain = true;
    peerConnection && peerConnection.close();
    peerConnection = null;
    iceCandidates = []
    localStream = null
    remoteStream = new MediaStream()
    patientVideoRef = null
    doctorVideoRef = null
    doctorAudioRef = null
  }

  const handleCandidateMessage = (msg) => {
    const candidate = new RTCIceCandidate(msg.candidate)
    iceCandidates.push(candidate)
  }

  const setIceCandidate = () => {
    iceCandidates.forEach((candidate) => {
      peerConnection.addIceCandidate(candidate)
    })
  }
  
  const onIceCandidate = (event) => {
    console.log('candidate', event);
    if (event.candidate) {
      callSocket.send(
        JSON.stringify({
          type: "candidate",
          candidate: event.candidate,
          dateSent: new Date(),
          customParameters: {
            nameValuePairs:{}
          },
          status: 0,
          isSentByUser: true,
        })
      );
    }
  }

  const checkConnectionEvent = store => e => {
    if (e.currentTarget.connectionState === "closed" ||
      e.currentTarget.connectionState === "disconnected") {
      console.log(e.currentTarget.connectionState);
      store.dispatch(setConnectedToPeer(false))
      stopCall(store)
    }
    if (e.currentTarget.connectionState === "connected") {
      store.dispatch(setConnectedToPeer(true))
      console.log('CONNECTED');
    }
    if (e.currentTarget.connectionState === "failed") {
      console.log('FAILED');
      closePeer()
      store.dispatch(setConnectedToPeer(false))
      store.dispatch(isBackgroundCallNeeded(false))
    }
  }


  const stopCall = async (store) => {
    console.log('ended');
    iceCandidates = []
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      stream.getTracks().forEach((tr) => {
        tr.stop()
      })
      console.log('localStream', localStream);
      localStream && localStream.getTracks().forEach((track) => {
        track.stop();
        localStream.removeTrack(track);
      });
      console.log(11);
      store.dispatch(changeChatMode(chatModes.CHAT))
      console.log(22);
      closePeer(store)
      store.dispatch(resetStatus())
      store.dispatch(setConnectedToPeer(false))
      store.dispatch(isBackgroundCallNeeded(false))
    } catch (e) {
      console.log('error', e);
    }
  }
  
  const onAddTrack = (event) => {
    remoteStream.addTrack(event.track, remoteStream);
    console.log('doctor stream', remoteStream);
    if (callType === 'video') {
      doctorVideoRef.srcObject = remoteStream
    }
    if (callType === 'audio') {
      doctorAudioRef.srcObject = remoteStream
    }
  }
  
  const asyncHandleReceiveCall = async (store, sdp) => {
    console.log('GETTING OFFER');
    patientVideoRef = document.getElementById('patientVideo')
    doctorVideoRef = document.getElementById('doctorsVideo')
    doctorAudioRef = document.getElementById('doctorAudio')
    try {
      const withVideo = callType === "video"
      const stream = await navigator.mediaDevices.getUserMedia({ video: withVideo, audio: true })
      localStream = stream
      if (callType === 'video') {
        patientVideoRef.srcObject = stream
      }
      stream.getTracks().forEach(tr => {
        localStream.addTrack(tr, stream)
        peerConnection.addTrack(tr, stream)
      })
      const desc = new RTCSessionDescription({
        type: "offer",
        sdp: sdp,
      })
      await peerConnection.setRemoteDescription(desc)
      setIceCandidate()
      const sessionDescription = await peerConnection.createAnswer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      })
      await peerConnection.setLocalDescription(sessionDescription)
      callSocket.send(JSON.stringify({
        type: 'answer',
        answer: sessionDescription.sdp,
        isSentByUser: true,
        customParameters: {
          nameValuePairs: {}
        }
      }))
      console.log('ANSWER SENT');
    } catch (e) {
      console.log('error', e);
    }
  }

  const toggleVideo = (store) => {
    localStream && localStream
      .getVideoTracks()
      .forEach((track) => (track.enabled = !track.enabled));
    store.dispatch(setVideoStatus())
  }

  const toggleAudio = (store) => {
    localStream && localStream
      .getAudioTracks()
      .forEach((track) => (track.enabled = !track.enabled));
    store.dispatch(setAudioStatus())
  }

  return store => next => async action => {
    switch (action.type) {
      case "chat/supportConnection":
        console.log('CONNECTED WS');
        if (socket !== null) {
          socket.close();
        }
        socket = io.connect(action.payload.host, {
          forceNew: true,
          rejectUnauthorized: false,
          query: {
            ...action.payload.query
          },
        })

        currentSocketMode = action.payload.isSupport ? "support" : "appointment"

        currentConsultId = action.payload.query.appointmentId
        socket.on("connect", () => {
          console.log("CONNECTED TO CHAT SOCKET");
          store.dispatch(setConnectionState(true))
          if (socketPool.length) {
            socketPool.forEach(cb => cb())
            socketPool = []
          }
        })
        socket.on("disconnect", () => {
          console.log("DISCONNECTED FROM SOCKET");
          store.dispatch(setConnectionState(false))
        })
        socket.on('message', onMessage(store))
        socket.on("close", onClose(store))
        socket.on("open", onOpen(store))
        socket.on("joinRoom", onJoinRoom(store))
        socket.on("chatRoomLeave", onLeaveRoom(store))
        break;
      
      case "chat/callConnection":
        if (callSocket !== null) {
          return
        }
        callSocket = io.connect(action.payload.host, {
          forceNew: true,
          rejectUnauthorized: false,
          query: {
            ...action.payload.query
          },
        })
        callSocket.on('message', onCallMessage(store))
        callSocket.on("close", onClose(store))
        callSocket.on("open", onOpen(store))
        break;
      case "notifications/createMessagingConnection":
        if ('Notification' in window) {
          messaging = firebase.messaging();
          messaging
            .requestPermission()
            .then(function () {
              messaging.getToken().then((currentToken) => {
                localStorage.setItem("sentFirebaseMessagingToken", currentToken);
                api.user.sendFirebaseToken(currentToken)
              });
            })
            .catch((err) => {
              console.log("Unable to get permission to notify.", err);
            });
          
          messaging.onMessageCallback = onNotification(store)
        }
        break;
      
      case "chat/wsSupportDisconnect":
        if (socket) {
          socket.close();
          socket = null;
          currentConsultId = null
          console.log("DISCONNECTED FROM CHAT SOCKET", socket);
        }
        break;
      
      case "chat/callSocketDisconnect":
        if (callSocket) {
          callSocket.close();
          callSocket = null;
          console.log("DISCONNECTED FROM CALL SOCKET", socket);
        }
        break;
      
      case 'chat/sendMessage':
        socket.send(JSON.stringify(action.payload.messageBody));
        break;
      case 'chat/rejectCall':
        callSocket.send(JSON.stringify({ type: "reject", customParameters: {} }))
        store.dispatch(changeChatMode(chatModes.CHAT))
        break;
      case 'chat/answerCall':
        if (callType === 'video') {
          store.dispatch(changeChatMode(chatModes.VIDEO))
        }
        if (callType === 'audio') {
          store.dispatch(changeChatMode(chatModes.AUDIO))
        }
        peerConnection = createPeer(store)
        // setTimeout(() => {
          console.log(callSocket);
          doctorAudioRef = document.getElementById('doctorAudio')
          callSocket.send(JSON.stringify({ type: 'ready' }))
          console.log("sent Ready");
        // }, 400)
        break;
      case 'chat/endCall':
        callSocket.send(JSON.stringify({
          type: 'ended',
          isSentByUser: true,
          status: 0,
          dateSent: new Date(),
          customParameters: {
            nameValuePairs:{}
          }
        }))
        console.log("ended call by user");
        stopCall(store)
        break;
      case "chat/startVideoChat":
        break;
      case "chat/muteVideo":
        toggleVideo(store)
        break;
      case "chat/muteAudio":
        toggleAudio(store)
        break;
      case "chat/chatRoomLeave":
        socket.send(JSON.stringify({ type: "chatRoomLeave" }))
        socket.close()
        socket = null;
        currentConsultId = null
        console.log('LEAVED CHAT');
        break;
      case "chat/sendGreetingMessage":
        const { doctor, date } = action.payload
        const formatDate = moment(date).format("DD MMMM")
        const formatTime = moment(date).format("HH:mm")
        socket.send(JSON.stringify({
          type: "chatBot",
          message: `${i18n.t("doctorGreetingFirstPart")} ${doctor}. ${i18n.t("doctorGreetingSecondPart")} ${formatDate} ${i18n.t("at")} ${formatTime}. ${i18n.t("doctorGreetingThirdPart")}.`,
          isSentByUser: false,
          dateSent: getCurrentDate(),
          customParameters: {
            some: "param",
          },
          status: "2",
        }))
        break;
      case "chat/sendAnamnezMessage":
        const { chatText, id } = action.payload
        disableAnamnezButtonNeeded = true
        function sendAnamMessage() {
          console.log("sending")
          socket.send(JSON.stringify({
            type: "chatBot",
            message: chatText,
            isSentByUser: false,
            dateSent: getCurrentDate(),
            customParameters: {
              question_id: id.toString(),
            },
            status: "2",
          }))
        }
        if (socket) {
          sendAnamMessage()
        } else {
          socketPool.push(sendAnamMessage)
        }
        break;
      case "chat/moveCallToBackground":
        action.payload.patientRef.current.srcObject = localStream
        action.payload.doctorRef.current.srcObject = remoteStream
        break;
      case "chat/updateCall":
        store.dispatch(isBackgroundCallNeeded(false))
        action.payload.patientRef.current.srcObject = localStream
        action.payload.doctorRef.current.srcObject = remoteStream
        break;
      case "chat/openCallFromBackground":
        callType = action.payload.callType
        store.dispatch(changeChatMode(action.payload.chatMode))
      default:
        return next(action);
    }
  };
};

export default socketMiddleware()

