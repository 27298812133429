import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  selectItem: {
    background: "white",
    color: "#3288cd",
    '&:hover': {
      background: "#f4f9fd"
    }
  },
  selectControl: {
    maxHeight: "100px",
  },
  paymentIcon: {
    position: 'absolute',
    right: "5px",
    bottom: "5px",
    width: "25px",
    height: "25px",
    color: '#3288cd',
  },
  paymentDoneIcon: {
    position: 'absolute',
    right: "10px",
    bottom: "5px",
    width: "20px",
    height: "20px",
    color: '#90ee90',
  }
});