import React from 'react'
import { useTranslation } from 'react-i18next'
import facebook from '../../../style/icons/facebook.svg'
import insta from '../../../style/icons/insta.svg'
import facebookActive from '../../../style/icons/facebookActive.svg'
import footerLogo from '../../../style/icons/footerLogo.svg'
import './footer.scss'
import { IconButton, makeStyles } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
// import InstagramIcon from '@material-ui/icons/Instagram';

const useStyles = makeStyles({
    icon: {
            width: "35px",
            height: "35px",
        }
})

export default function Footer() {
    const { t } = useTranslation()
    const classes = useStyles()
    return (
        <div className='footer'>
            <a target='_blank' rel="noreferrer" href="https://dobrodoc.ua">
                <img className='footerImage' src={footerLogo} alt="dobrodoc" />
            </a>
            <div className='infoRefs'>
                <div><a target='_blank' rel="noreferrer" href="https://b2c.dobrodoc.ua/pro-nas">{t("aboutCompany")}</a></div>
                <div><a target='_blank' rel="noreferrer" href="https://b2c.dobrodoc.ua/yuridichna-informatsiya">{t("legalInformation")}</a></div>
            </div>
            <div className='infoRefs2'>
                <div><a target='_blank' rel="noreferrer" href="https://b2c.dobrodoc.ua/tarifi">{t("rates")}</a></div>
                <div><a target='_blank' rel="noreferrer" href="https://b2c.dobrodoc.ua/likari">{t("doctors")}</a></div>
                <div><a target='_blank' rel="noreferrer" href="https://b2c.dobrodoc.ua/novini">{t("news")}</a></div>
                <div><a target='_blank' rel="noreferrer" href="https://b2c.dobrodoc.ua/priemni-vinagorodi-za-shchodenni-pokupki">{t("Fishka")}</a></div>
            </div>
            <div className='infoContacts'>
                {/* <div>0 (800) 33-00-36 8</div> */}
                <a target='_blank' rel="noreferrer" href="https://www.facebook.com/dobrodoc.ua">
                    <IconButton className={classes.button} variant='contained' color='primary'>
                        <FacebookIcon className={classes.icon}/>
                    </IconButton>
                </a>
                <a target='_blank' rel="noreferrer" href="https://www.instagram.com/dobrodoc.ua/">
                    <IconButton className={classes.button} variant='contained' color='primary'>
                        <InstagramIcon className={classes.icon} color='primary'/>
                    </IconButton>
                </a>
                <a target='_blank' rel="noreferrer" href="https://www.youtube.com/watch?v=F-aEciiYDRM">
                    <IconButton className={classes.button}>
                        <YouTubeIcon className={classes.icon} color='secondary'/>
                    </IconButton>
                </a>
            </div>
        </div>
    )
}
