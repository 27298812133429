import React from 'react';
import { useStyles } from './styles';
import './titleComponent.scss'
import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Spinner from '../ui/Spinner/Spinner';

const TitleComponent = ({
  title,
  icon,
  redirectUrl,
  customIcon,
  titlePrimary,
  titleSecondary,
  fullWidthIcon,
  loading,
  goBack,
  marginTop,
  noIconColor,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  
  const handleRedirect = () => {
    if (goBack) {
      history.goBack()
    } else {
      history.push(redirectUrl ?? '/clinic')
    }
  }

  return (
    <div style={marginTop ? {marginTop: marginTop} : {}} className="titleContainer">
      <IconButton
        className={classes.backButton}
        variant='contained'
        color='primary'
        onClick={handleRedirect}
        title={t("back")}
      >
        <ArrowBackIosTwoToneIcon />
      </IconButton>
      <div className="pageIcon">
        {/* <RecentActorsIcon className={classes.pageIcon}/> */}
        {icon && <img style={fullWidthIcon ? {width: "54px", height: "54px", borderRadius: "50%"} : {}}  width='40' height='40' src={icon} alt="page-header-icon" />}
        {customIcon && (
          <div style={noIconColor ? {background: "white"} : {}} className="customIconBox">
            <img className={noIconColor ? `centerPosition` : ``} width='35' height='35' src={customIcon} alt="page-header-icon" />
          </div>
        )}
      </div>
      {title && <div className='medConsultTitle'>{title}</div>}
      {titlePrimary && titleSecondary && (
        <div className="medConsultTitle twoRows">
          <div className="primary">{titlePrimary}</div>
          <div className="secondary">{titleSecondary}</div>
        </div>
      )}
      {loading && (
        <div className="spinnerBox">
          <Spinner width={30} height={30}/>
        </div>
      )}
    </div>
  );
}

export default TitleComponent;
