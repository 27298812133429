import React from 'react'
import clinic from '../../../style/icons/Sider/clinic.svg'
import medCards from '../../../style/icons/Sider/medCards.svg'
import notification from '../../../style/icons/Sider/notification.svg'
import consultations from '../../../style/icons/Sider/consultations.svg'
import analysis from '../../../style/icons/Sider/analysis.svg'
import support from '../../../style/icons/Sider/support.svg'
import drugs from '../../../style/icons/Sider/pharmacy.svg'
import doctors from '../../../style/icons/Sider/doctors.svg'
import logo from '../../../style/icons/logo.svg'
import './sider.scss'
import { withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { createMessagingConnection, setUnreadedNotificationsCount } from '../../../redux/notifications/notifications-actions'
import { NavLink } from 'react-router-dom'
import LocalPharmacyOutlinedIcon from '@material-ui/icons/LocalPharmacyOutlined';
import { clearSearch } from '../../../redux/specializations/specializations-actions'

const Sider = ({
  history,
  location: { pathname },
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const unreadedCount = useSelector(state => state.notifications.unreadedNotificationsCount)
  const countFromSaved = +localStorage.getItem('unreadedCount')
  useEffect(() => {
    dispatch(createMessagingConnection())
  }, []);

  useEffect(() => {
    if (countFromSaved) {
      dispatch(setUnreadedNotificationsCount(countFromSaved))
    }
  }, [])

  return (
    <div className='sider'>
      <div className="siderLogo">
        <img
          width='70'
          height='58'
          onClick={() => history.push('/clinic')}
          src={logo}
          alt="logo"
        />
      </div>
      <div className='siderOptions'>
        <NavLink to='/clinic' onClick={() => dispatch(clearSearch())}>
          <div className='optionBox'>
            <img
              width='46'
              height='46'
              className={`siderRoute ${pathname.includes('clinic') ? '' : 'notVisited'}`}
              src={clinic}
              alt="clinic"
            />
            <div className='siderItemTitle'>{t("Clinic")}</div>
          </div>
        </NavLink>
        <NavLink to='/medical-cards'>
          <div className="optionBox">
            <img
              width='46'
              height='46'
              className={`siderRoute ${(pathname.includes('medical-cards') || pathname.includes('new-card')) ? '' : 'notVisited'}`}
              src={medCards}
              alt="medCards"
            />
            <div className='siderItemTitle'>{t("medicalCards")}</div>
          </div>
        </NavLink>
        <NavLink to='/consultations'>
          <div className="optionBox">
            <img
              width='46'
              height='46'
              className={`siderRoute ${pathname.includes('consultations') ? '' : 'notVisited'}`}
              onClick={() => {
                history.push('/consultations')
              }}
              src={consultations}
              alt="consultations"
            />
            <div className='siderItemTitle'>{t('Consultations')}</div>
          </div>
        </NavLink>
        <NavLink to='/notifications'>
          <div className={`optionBox ${(unreadedCount != 0 && countFromSaved != 0) ? 'withIcon' : ''}`}>
            {(unreadedCount != 0 && countFromSaved != 0) && (
              <div className='unreadedCounter'>{unreadedCount || countFromSaved}</div>
            )}
            <img
              width='46'
              height='46'
              className={`siderRoute ${pathname.includes('notifications') ? '' : 'notVisited'}`}
              src={notification}
              alt="notifications"
            />
            <div className="siderItemTitle">{t("Notifications")}</div>
          </div>
        </NavLink>
        {/* <NavLink to='/analysis'>
          <div className="optionBox">
            <div className={`analysisBox ${pathname.includes('analysis') ? '' : 'notVisited'}`}>
              <img
                className={`siderRoute`}
                src={analysis}
                alt="notifications"
              />
            </div>
            <div className="siderItemTitle">{t("medicalTests")}</div>
          </div>
        </NavLink> */}
        <NavLink to='/support'>
          <div className="optionBox">
            <div className={`analysisBox ${pathname.includes('support') ? '' : 'notVisited'}`}>
              <img
                width='46'
                height='46'
                className={`siderRoute`}
                src={support}
                alt="support"
              />
            </div>
            <div className="siderItemTitle">{t("Support")}</div>
          </div>
        </NavLink>
        <NavLink to='/drugs'>
          <div className="optionBox">
            <div className={`analysisBox ${pathname.includes('drugs') ? '' : 'notVisited'}`}>
              <img
                width='30'
                height='30'
                className={`siderRoute`}
                src={drugs}
                alt="support"
              />
            </div>
            <div className="siderItemTitle">{t("pharmacy")}</div>
          </div>
        </NavLink>
        <NavLink to='/doctors/1'>
          <div className="optionBox">
            <div className={`analysisBox ${pathname.includes('doctors') ? '' : 'notVisited'}`}>
              <div className='customIconBox'>
                <img
                width='26'
                height='26'
                className={`siderRoute`}
                src={doctors}
                alt="doctors"
              />
              </div>
            </div>
            <div className="siderItemTitle">{t("doctors")}</div>
          </div>
        </NavLink>
      </div>
    </div>
  )
}

export default withRouter(Sider);