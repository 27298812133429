import React from 'react';
import { useHistory } from 'react-router-dom';
import './loadingPlaceholder.scss'
import logo from '../../../style/icons/onlyLogo.svg'

const LoadingPlaceholder = () => {
  
  return (
    <div className='loadingPlaceholderBox'>
      
      <img
          className='logo'
          width='200'
          height='200'
          src={logo}
          alt="logo-placeholder"
        />
       <div className="logoBox"></div>
    </div>
  )
  // return (
  //   <div className='bufferSection'>
  //     <div className='placeholderNavigation'>
  //       <div className="placeholderNavItem logo"></div>
  //       <div className="placeholderNavItem"></div>
  //       <div className="placeholderNavItem"></div>
  //       <div className="placeholderNavItem"></div>
  //       <div className="placeholderNavItem"></div>
  //       <div className="placeholderNavItem"></div>
  //     </div>
  //     <div className='placeholderHeader'>
  //       <div className="placeholderHeaderItem search"></div>
  //       <div className="placeholderHeaderItem fishka"></div>
  //       <div className="placeholderHeaderItem toolbar"></div>
  //     </div>
  //     <div className='placeholderFooter'>
  //       <div className="placeholderFooterItem circle"></div>
  //       <div className="placeholderFooterItem text"></div>
  //       <div className="placeholderFooterItem text"></div>
  //       <div className="placeholderFooterItem text"></div>
  //       <div className="placeholderFooterItem text"></div>
  //       <div className="footerBox">
  //         <div className="placeholderFooterItem circle"></div>
  //         <div className="placeholderFooterItem circle"></div>
  //         <div className="placeholderFooterItem circle"></div>
  //       </div>
  //     </div>
  //     <div className='placeholderContent'>
  //       {pathname.includes('clinic') && (<>
  //         <div className="contentTitleBox">
  //           <div className="title"></div>
  //         </div>
  //         <div className='contentBox'>
  //           <div className="placeholderContentItem"></div>
  //           <div className="placeholderContentItem"></div>
  //           <div className="placeholderContentItem"></div>
  //           <div className="placeholderContentItem"></div>
  //           <div className="placeholderContentItem"></div>
  //           <div className="placeholderContentItem"></div>
  //           <div className="placeholderContentItem"></div>
  //           <div className="placeholderContentItem"></div>
  //           <div className="placeholderContentItem"></div>
  //         </div>
  //       </>)}
  //     </div>
  //     <div className='placeholderSidebar'>
  //       <div className="placeholderSidebarItem"></div>
  //       <div className="placeholderSidebarItem"></div>
  //       <div className="placeholderSidebarItem"></div>
  //     </div>
  //   </div>
  // );
}

export default LoadingPlaceholder;
