import React, { useRef } from 'react';
import './chat1.scss'
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { LoadingComponent } from '../../pages/ConsultationBooking/ConsultationBooking';
import attachment from '../../../style/icons/Chat/attachment.svg'
import mesTypes from '../../../utils/chatTypes'
import { connect } from 'react-redux';
import {
  getAppoitmentChatHistory,
  getMessagesBySearch,
  getSupportChatHistory,
  sendPushToOperator,
  uploadChatFile,
  uploadSupportFile
} from '../../../redux/chat/chat-operations';
import { setChatMemberCount, sendMessage, changeChatMode, setCallInfo, wsSupportConnect, wsCallConnection, wsSupportDisconnect, clearMessages, resetSearch, sendGreetingMessage, sendAnamnezMessage } from '../../../redux/chat/chat-actions';
import { bytesToSize, getCurrentDate, getFileType, getMessageTime } from '../../../utils/helpers';
import info from '../../../utils/info';
import 'react-circular-progressbar/dist/styles.css';
import { useHistory, useParams} from 'react-router-dom';
import deleteIcon from '../../../style/icons/Close/cancel.svg'
import {useDropzone} from 'react-dropzone'
import ModalWindow from '../../components/ModalWindow/ModalWindow'
import { IconButton } from '@material-ui/core';
import ScrollableFeed from 'react-scrollable-feed'
import { sortByDate } from '../../../utils/sort';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import activeDocIcon from "../../../style/icons/Doc/doc-blue.svg";
import OrdinaryMessage from './MessagesTypes/OrdinaryMessage';
import AudioMessage from './MessagesTypes/AudioMessage';
import VideoMessage from './MessagesTypes/VideoMessage';
import ImageMessage from './MessagesTypes/ImageMessage';
import FileMessage from './MessagesTypes/FileMessage';
import ChatInputPanel from './ChatInputPanel/ChatInputPanel';
import moment from 'moment';
import TitleComponent from '../TitleComponent/TitleComponent';
import support from '../../../style/icons/Sider/support.svg'
import DateSearch from '../DateSearch/DateSearch';
import { disableAnamnesis, getConsultAnamnesis } from '../../../redux/consultations/consultations-operations';
import { getAnamnesisList } from '../../../redux/consultations/consultations-selectors';
import { resetAnamnesis } from '../../../redux/consultations/consultations-actions';
import { getChatMessages, getIsConnectedToSocket } from '../../../redux/chat/chat-selectors';
import { Detector } from "react-detect-offline";
import Carousel from '../ui/Carousel/Carousel'
import { InternetStateComponent } from "./ConnectionState/ConnectionState"
import { getChatHistory } from '../../../redux/socket/socketActions';
import useUpdateEffect from '../../../hooks/useUpdateEffect';

const validForDateEnd = moment("28/10/2021", "DD/MM/YYYY").subtract(1, "day")

export const validDateStart = function( current ){
    return current.isAfter( validForDateEnd );
};

const Chat1 = ({
  getSupportChatHistory,
  messages,
  loading,
  uploadChatFile,
  sendMessage,
  areBothInChat,
  uploadSupportFile,
  getAppoitmentChatHistory,
  supportMode,
  consultationInfo,
  handleGetFromStorage,
  sidebarMode,
  chatOpenedWithVideo = true,
  setChatOpenedWithVideo,
  fullScreenChat,
  sendPushToOperator,
  audioAllowed,
  currentPage,
  nextPageLoading,
  isTheEndPage,
  totalPageCount,
  getMessagesBySearch,
  messagesBySearch,
  resetSearch,
  getConsultAnamnesis,
  anamnesis,
  sendAnamnezMessage,
  disableAnamnesis,
  resetAnamnesis,
  getChatHistory,
  // appointmentId,
  clearMessages,
}) => {
  const [statusUploadFile, setStatusUploadFile] = useState(0);
  const [messagesByDate, setMessagesByDate] = useState({});
  const [page, setPage] = useState(0)
  const [searchPage, setSearchPage] = useState(0)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [imageSrc, setImageSrc] = useState(null)
  const [isAtBottom, setIsAtBottom] = useState(true)
  const { t } = useTranslation()
  const history = useHistory()
  const chatInputRef = useRef()
  const fileInputRef = useRef()
  const mediaInputRef = useRef()
  const locationArray = history.location.pathname.split('/')
  const currentConsultId = +locationArray[locationArray.length - 1]
  const absoluteDateRef = useRef()
  const chatAreaRef = useRef()
  const dateArray = useRef([])
  const scrollAreaRef = useRef(null)
  const prevScrollPosition = useRef(null)
  const appointmentId = new URLSearchParams(history.location.search).get("id");

  const getHistory = () => {
    const isSearchingByDate = startDate || endDate;
    if (page === 0) {
      console.log("CLEARING MESSAGES");
      // clearMessages()
    }
    getChatHistory({
      page: isSearchingByDate ? 0 : page,
      userId: info.getUserId(),
      startDate: startDate,
      endDate: endDate,
      scrollHandler: scrollOnNextPage,
      isSupport: !!supportMode,
      appointmentId: appointmentId,
      typeRoom: supportMode
        ? "patientToOperator"
        : "patientToDoctor",
    })
  }

  useEffect(() => { getHistory() }, [supportMode])
  useUpdateEffect(() => {getHistory()}, [page, startDate, endDate])

  useEffect(() => {
    if (anamnesis.length > 0) {
      sendAnamnezMessage(anamnesis[0])
    }
  }, [anamnesis])

  useEffect(() => {
    if (messagesBySearch.length > 0) {
      setMessagesByDate(sortByDate(messagesBySearch))
    } else {
      setMessagesByDate(sortByDate(messages))
    }
  }, [messages, messagesBySearch])

  const handleSendMessage = (value, type) => {
    const messageBody = {
      messageType: supportMode
        ? "patientToOperator"
        : "patientToDoctor",
      type: type || mesTypes.MESSAGE,
      message: value,
      dateSent: getCurrentDate(),
      isSentByUser: true,
      customParameters: {
        some: "param",
      },
      appointmentId: appointmentId || "",
      status: areBothInChat ? "2" : "1"
    }
    sendMessage({ messageBody, isSupport: supportMode })

    handleScrollBottom()
  }
  
  ////SEND AUDIO MESSAGE
  const handleSendAudioMessage = (file, duration) => {
    const socketBodyWithoutMessage = {
      messageType: supportMode
        ? "patientToOperator"
        : "patientToDoctor",
      type: "voice",
      dateSent: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      isSentByUser: true,
      appointmentId: appointmentId || "",
      customParameters: {
        some: "sam some",
        audioDuration: duration || '',
      },
    }
    const requestBody = {
      appointmentId: appointmentId,
      file: file,
      setUploadStatus: setStatusUploadFile,
    }

    const supportRequestBody = {
      userId: info.getUserId(),
      file: file,
      setUploadStatus: setStatusUploadFile,
    }
    if (supportMode) {
      uploadSupportFile(supportRequestBody, socketBodyWithoutMessage)
    } else {
      uploadChatFile(requestBody, socketBodyWithoutMessage)
    }
  }


  /////SEND FILE (FILE OR MEDIA)
  const handleAttach = (e = null, file = null) => {
    const inputFile = e?.target?.files[0]
    
    const requestBody = {
      appointmentId: appointmentId,
      file: inputFile || file,
      setUploadStatus: setStatusUploadFile,
    }

    const supportRequestBody = {
      userId: info.getUserId(),
      file: inputFile || file,
      setUploadStatus: setStatusUploadFile,
    }

    const socketBodyWithoutMessage = {
      messageType: supportMode
        ? "patientToOperator"
        : "patientToDoctor",
      type: getFileType(inputFile?.type || file?.type),
      dateSent: getCurrentDate(),
      isSentByUser: true,
      appointmentId: appointmentId || "",
      customParameters: {
        file_name: inputFile?.name || file?.name,
        size: bytesToSize(inputFile?.size || file?.size),
      },
      status: areBothInChat ? "2" : "1"
    }
    if (supportMode) {
      uploadSupportFile(supportRequestBody, socketBodyWithoutMessage)
      if (e) {
        handleClearInput(e.target)
      }
      return
    }
    uploadChatFile(requestBody, socketBodyWithoutMessage)
    if (e) {
      handleClearInput(e.target)
    }
  }

  //CLEARING INPUT AFTER SENDING FILE
  const handleClearInput = (input) => {
    input.value = null
  }

  //CHAT DROP ZONE
  const onDrop = useCallback(acceptedFiles => {
    console.log('acceptedFiles', acceptedFiles);
    handleAttach(null, acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  /////OPEN MODAL
  const handleOpenImage = (e) => {
    setImageSrc(e.target.src)
  }

  ////CLOSE MODAL
  const handleCloseModal = () => {
    setImageSrc(null)
  }

  ////DISABLING ANAMNESIS QUESTIONS
  const handleDisableAnamnez = () => {
    disableAnamnesis(consultationInfo?.id)
  }

  ///// RENDERING DIFFERENT TYPES OF MESSAGES
  const _renderMessage = (type, id, props) => {
    switch (type) {
      case mesTypes.MESSAGE:
        return <OrdinaryMessage key={id} areBothInChat={areBothInChat} {...props} />
      case mesTypes.VOICE:
        return <AudioMessage key={id} areBothInChat={areBothInChat} {...props} />
      case mesTypes.IMAGE:
        return <ImageMessage key={id} handleOpenImage={handleOpenImage} areBothInChat={areBothInChat} {...props} />
      case mesTypes.FILE:
        return <FileMessage key={id} areBothInChat={areBothInChat} {...props} />
      case mesTypes.VIDEO:
        return <VideoMessage key={id} areBothInChat={areBothInChat} {...props} />
      case mesTypes.CHAT_BOT:
        return <OrdinaryMessage handleDisableAnamnez={handleDisableAnamnez} key={id} areBothInChat={areBothInChat} {...props} />
      case mesTypes.CANCELATION:
        return <OrdinaryMessage key={id} areBothInChat={areBothInChat} {...props} />
      case mesTypes.FINISHED:
        return <OrdinaryMessage key={id} areBothInChat={areBothInChat} {...props} />
      default:
        return
    }
  }
  ///AUTO RESIZING FOR CHAT INPUT
  useEffect(() => {
    if (chatInputRef.current) {
      document.querySelectorAll('[data-autoresize]').forEach(function (element) {
        element.style.boxSizing = 'border-box';
        var offset = element.offsetHeight - element.clientHeight;
        element.addEventListener('input', function (event) {
          event.target.style.height = 'auto';
          event.target.style.height = event.target.scrollHeight + offset + 'px';
        });
        element.removeAttribute('data-autoresize');
      });
    }
  }, [chatInputRef])

  ///scroll chat to bottom
  const handleScrollBottom = () => {
    const ref = document.querySelector(".scrollArea")
    ref.scrollTo({
      top: 9999999
    })
  }

  ///Calculating scroll redirect after fetching next page
  const calculateScrollPosition = () => {
    const { scrollHeight, clientHeight } = scrollAreaRef.current.wrapperRef.current
    const currentPosition = currentPage === 0
      ? 0
      : scrollHeight - clientHeight
    const delta = currentPosition - prevScrollPosition.current
    const scrollToPosition = delta < 0 ? prevScrollPosition.current : delta
    return scrollToPosition
  }

  const scrollOnNextPage = () => {
    console.log("calculateScrollPosition()", calculateScrollPosition());
    setTimeout(() => {
      scrollAreaRef.current.wrapperRef.current.scrollTo({
        top: calculateScrollPosition(),
      })
    }, 100)
  }

  console.log(page);

  ////SCROLL EVENT TO dynamically CALCULATE DATE IN CHAT
  const handleScroll = (isAtBottom) => {
    if (!isAtBottom) {
      setIsAtBottom(false)
    }
    if (isAtBottom) {
      setIsAtBottom(true)
    }

    const { scrollTop, clientHeight, scrollHeight } = scrollAreaRef.current.wrapperRef.current
    const isTheEnd = scrollTop === 0
    if (isTheEnd && !nextPageLoading && !isTheEndPage) {
      prevScrollPosition.current = scrollHeight - clientHeight
      // fetchNextPage()
      setPage(prev => prev + 1)
    }

    const top = scrollAreaRef.current.wrapperRef.current.scrollTop;
    for (let i = 0; i < dateArray.current.length - 1; i++) {
      if (top <= 5) {
        absoluteDateRef.current.textContent = ''
        return
      }
      if (dateArray.current[i]?.offsetTop < top && dateArray.current[i + 1]?.offsetTop > top) {
        absoluteDateRef.current.textContent = dateArray.current[i].textContent;
        return
      }
    }
  }

  ///Searching By Date
  const handleSearchByDate = ({ startDate, endDate }) => {
    setStartDate(startDate)
    setEndDate(endDate)
    // getMessagesBySearch({
    //   startDate,
    //   endDate,
    //   page: 0,
    //   userId: info.getUserId(),
    //   inSupportChat: supportMode ? true : false
    // })
  }

  ///reseting after clearing date search
  const handleResetSearch = () => {
    setStartDate(null)
    setEndDate(null)
    resetSearch()
  }


  return (
    <div className={`pageContainer ${sidebarMode ? 'small' : ''} ${!chatOpenedWithVideo ? 'dnone' : ''}`}>
      {!sidebarMode && (consultationInfo || supportMode) && (
        <div className="pageHeaderBox">
          <TitleComponent
            loading={nextPageLoading}
            fullWidthIcon={supportMode ? false : true}
            icon={supportMode ? support : consultationInfo?.doctor?.employee?.photoUrl || activeDocIcon}
            title={supportMode ? t("Support") : null}
            titlePrimary={!supportMode && consultationInfo?.doctor?.employee?.fullName}
            titleSecondary={!supportMode && consultationInfo?.specialty?.title}
          />
          <DateSearch
            isValidDate={validDateStart}
            handleReset={handleResetSearch}
            handleSearch={handleSearchByDate}
          />
        </div>
      )}
      {!sidebarMode && !consultationInfo && !supportMode && (
        <div className="pageHeaderBox loading">
          <Carousel />
        </div>
      )}
      <div
        className={`chatContainer ${sidebarMode
          ? 'small' : ''} ${!chatOpenedWithVideo ? 'dnone' : ''}`}
        {...getRootProps()}
        onClick={(e) => { }}
        onKeyDown={(e) => { }}
        onKeyPress={(e) => { }}
        onKeyUp={(e) => { }}
      >
        {sidebarMode && !supportMode && (
          <div className='closePanel'>
            {/* {fullScreenChat && ( */}
            <img
              onClick={() => setChatOpenedWithVideo(false)}
              src={deleteIcon}
              alt="close-panel"
              title={'Закрыть чат'}
            />
            {/* )} */}
          </div>
        )}
        {isDragActive && (
          <div className='dragNdrop'>
            <div className='dropFileContainer'>
              <div className='dropFileText'>Отправить Файл...</div>
            </div>
          </div>
        )}
        <div
          ref={chatAreaRef}
          // style={supportMode ? {} : { height: "79.5%" }}
          className={`chatArea ${sidebarMode ? 'small' : ''}`}
        >
          <div ref={absoluteDateRef} className='absoluteDate isVisible'></div>
          {!isAtBottom && (
            <IconButton
              style={{ position: "absolute", bottom: "10px", left: "30px", zIndex: "99999" }}
              variant='contained'
              color='primary'
              onClick={handleScrollBottom}
              title={t("back")}
            >
              <ArrowDownwardSharpIcon style={{ width: "30px" }} />
            </IconButton>
          )}
          <input
            {...getInputProps()}
            style={{ border: "none", outline: 'none', width: '0px', height: "0px" }}
            onKeyDown={(e) => { }}
            onClick={(e) => { }}
            onKeyDown={(e) => { }}
            onKeyPress={(e) => { }}
            onKeyUp={(e) => { }}
          />
          <LoadingComponent loading={loading}>
            {
              <ScrollableFeed
                onScroll={handleScroll}
                className='scrollArea'
                ref={scrollAreaRef}
              >
                {Object.keys(messagesByDate).map((date, i) => {
                  return (<>
                    <div key={i} id={i} ref={(ref) => dateArray.current[i] = ref} className='date'>{date}</div>
                    {messagesByDate[date].map(mes => {
                      return _renderMessage(mes.type, mes._id, { ...mes })
                    })}
                  </>)
                })}
              </ScrollableFeed>
            }
          </LoadingComponent>
        </div>
        <Detector
          render={({ online }) => {
            if (online) {
              return (
                <ChatInputPanel
                  supportMode={supportMode}
                  audioAllowed={audioAllowed}
                  statusUploadFile={statusUploadFile}
                  chatInputRef={chatInputRef}
                  handleAttach={handleAttach}
                  fileInputRef={fileInputRef}
                  mediaInputRef={mediaInputRef}
                  handleSendMessage={handleSendMessage}
                  handleSendAudioMessage={handleSendAudioMessage}
                />
              )
            } else {
              return (
                <div className='textareaChatContainer'>
                  <InternetStateComponent t={t} />
                </div>
              )
            }
          }}
        />
        
        <ModalWindow handleCloseModal={handleCloseModal} open={imageSrc} imageFormat>
          <img className='chatPicture' src={imageSrc} alt="chatPicture" />
        </ModalWindow>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.chat.loading,
  messages: getChatMessages(state),
  areBothInChat: state.chat.areBothInChat,
  chatMode: state.chat.chatMode,
  error: state.chat.error,
  audioAllowed: state.chat.audioAllowed,
  currentPage: state.chat.currentPage,
  nextPageLoading: state.chat.nextPageLoading,
  isTheEndPage: state.chat.isTheEndPage,
  totalPageCount: state.chat.totalPageCount,
  messagesBySearch: state.chat.messagesBySearch,
  anamnesis: getAnamnesisList(state),
})

const mapDispatchToProps = {
  getSupportChatHistory,
  uploadChatFile,
  sendMessage,
  changeChatMode,
  setChatMemberCount,
  uploadSupportFile,
  getAppoitmentChatHistory,
  setCallInfo,
  wsSupportConnect,
  wsCallConnection,
  wsSupportDisconnect,
  clearMessages,
  sendPushToOperator,
  getMessagesBySearch,
  resetSearch,
  getConsultAnamnesis,
  sendGreetingMessage,
  sendAnamnezMessage,
  disableAnamnesis,
  resetAnamnesis,
  getChatHistory,
}


export default connect(mapStateToProps, mapDispatchToProps)(Chat1);
