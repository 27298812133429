import { createReducer } from "@reduxjs/toolkit";
import { combineReducers } from '@reduxjs/toolkit'
import {
  getSpecializationsSuccess,
  getSpecializationsError,
  clearSearch,
} from './specializations-actions'
import {
  getSymptomsSuccess,
} from '../symptoms/symptoms-actions'

const specializationsReducer = createReducer(null, {
  [getSpecializationsSuccess]: (_, { payload }) => payload?.data || null,
  [getSpecializationsError]: (_, { payload }) => payload,
  // [getSymptomsSuccess]: (_, {payload}) => payload?.specialty || null,
})

const specializationsBySearch = createReducer(null, {
  [getSymptomsSuccess]: (_, { payload }) => payload?.specialty || null,
  [clearSearch]: (_, __) => null
})

const reseted = createReducer(false, {
  [clearSearch]: (state) => !state,
})

// export default specializationsReducer
export default combineReducers({
  items: specializationsReducer,
  itemsBySearch: specializationsBySearch,
  reseted,
});