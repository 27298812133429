import { createSelector } from "@reduxjs/toolkit";

// const defaultState = { popup: { ...defaultConsultations }};

const getPopup = (state = {}) =>
  state.popup;

export const getPopups = createSelector(
  [getPopup],
  (popups) => popups
);
// export const getPopupMessage = createSelector(
//   [getPopup],
//   ({ message }) => message
// );
// export const getShowPopup = createSelector(
//   [getPopup],
//   ({ showPopup }) => showPopup
// );
// export const getPopupColor = createSelector(
//   [getPopup],
//   ({ color }) => color
// );
