import React, {useEffect, useState} from 'react'
import './home.scss'
import Footer from '../../components/Footer/Footer'
import Sider from '../../components/Sider/Sider'
import Navbar from '../../components/Navbar/Navbar'
import { connect } from 'react-redux'
import { getCurrentUser, getProfile, getUserCards } from '../../../redux/auth/auth-operations'
import AllRoutes from '../../../routes/routes'
import { useHistory } from 'react-router'
import { getSubscriptionInfo } from '../../../redux/subscription/subscription-operations'
import { getIsGotInfo } from '../../../redux/consultations/consultations-selectors'
import ModalWindow from '../../components/ModalWindow/ModalWindow'
import { setModalCallInfo } from '../../../redux/chat/chat-actions'
import callSound from '../../../audios/call.mp3'
import { closeFishka } from '../../../redux/fishka/fishka-actions'
import { getIsFishkaOpen } from '../../../redux/fishka/fishka-selectors'
import FishkaMain from '../../components/FishkaMain/FishkaMain'
import { calculateConsultationBonuses, calculateSubscriptionBonuses, getPatientBonuses } from '../../../redux/fishka/fishka-operations'
import Sidebar from '../../components/Sidebar/Sidebar'
import { Callmodal } from '../../components/CallModal/CallModal'
import { RefToMobile } from '../../components/RefToMobile/RefToMobile'
import { PopupPermissions } from '../../components/PopupPermission/PopupPermission'
import BackgroundCall from '../../components/BackgroundCall/BackgroundCall'

function Home({
  profile,
  closeFishka,
  getProfile,
  callModalInfo,
  setModalCallInfo,
  isFishkaOpen,
  getPatientBonuses,
  getUserCards,
  currentPath,
}) {
  const [isShown, setIsShown] = useState(false)
  const callAudio = React.useRef(new Audio(callSound))
  const history = useHistory()
  const { location: { pathname } } = history

  useEffect(() => {
    if (callModalInfo) {
      callAudio.current.play()
      callAudio.current.volume = 0.3;
    } else {
      callAudio.current.pause()
    }
  }, [callModalInfo])

  useEffect(() => {
    localStorage.setItem('currentLocation', pathname)
    
  }, [pathname])

  useEffect(() => {
    Notification.requestPermission((perm) => {
      if (perm === 'denied') {
        setIsShown(true)
        setTimeout(() => {
          setIsShown(false)
        }, 10000)
      }
      if (perm !== 'denied') {
        setIsShown(true)
        setTimeout(() => {
          setIsShown(false)
        }, 10000)
      }
      if (perm === 'granted') {
        setIsShown(false)
      }
    })
  }, [])

  const handleCloseCallModal = () => {
    setModalCallInfo(null)
  }

  useEffect(() => {
    getProfile()
    getPatientBonuses()
    // getUserCards()
  }, [])

  useEffect(() => {
    if (profile) {
      localStorage.setItem("userId", profile.userId)
    }
  }, [profile])


  const handleCloseFishka = () => {
    closeFishka()
  }

  return (
    <>
      <main>
        <div className='appWrapper'>
          <div className='siderArea'>
            <Sider />
          </div>
          <div className="headerArea">
            <Navbar />
          </div>
          <div className="footerArea">
            <Footer />
          </div>
          <div className="contentArea">
            <AllRoutes currentPath={currentPath}/>
          </div>
          <div className="sidebarArea">
            <Sidebar />
          </div>
        </div>
        <ModalWindow
          open={callModalInfo}
          handleCloseModal={handleCloseCallModal}
          confirmMode
        >
          <Callmodal />
        </ModalWindow>
        <ModalWindow open={isFishkaOpen} handleCloseModal={handleCloseFishka} confirmMode>
          <FishkaMain />
        </ModalWindow>
        {isShown && (
          <PopupPermissions />
        )}
      </main>
      <RefToMobile />
      <BackgroundCall />
    </>
  )
}

const mapStateToProps = ({ user, symptoms, ...state }) => ({
  user: user && user.userInfo,
  profile: user.profile,
  isGotInfo: getIsGotInfo(state),
  isFishkaOpen: getIsFishkaOpen(state),
  callModalInfo: state?.chat?.callModalInfo,
  // isActiveCall: state?.chat?.isPeerConnected,
})

const mapDispatchToProps = {
  getCurrentUser,
  getSubscriptionInfo,
  getProfile,
  setModalCallInfo,
  closeFishka,
  getPatientBonuses,
  calculateSubscriptionBonuses,
  calculateConsultationBonuses,
  getUserCards,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
