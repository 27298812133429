import React, { useEffect, useRef, useState } from 'react'
import previousIcon from '../../../style/icons/Subscription/previousIcon.svg'
import { useHistory, useParams } from 'react-router'
import './consultationBooking.scss'
import Button from '../../components/ui/Button/Button'
import online from '../../../style/icons/consultationTypes/online.svg'
import onlineActive from '../../../style/icons/consultationTypes/onlineActive.svg'
import clinic from '../../../style/icons/consultationTypes/atClinic.svg'
import clinicActive from '../../../style/icons/consultationTypes/atClinicActive.svg'
import home from '../../../style/icons/consultationTypes/atHome.svg'
import homeActive from '../../../style/icons/consultationTypes/atHomeActive.svg'
import iconChecked from '../../../style/icons/consultationTypes/iconChecked.svg'
import clinicIcon from '../../../style/icons/Analyses/clinicIcon.svg'
import patient_active from "../../../style/icons/Subscription/patient_active.svg";
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import plusIcon from '../../../style/icons/Plus/plusIcon.svg'
import { Field, getFormValues, reduxForm } from 'redux-form'
import { compose } from 'redux'
import oneDoctor from '../../../style/icons/Doctors/oneDoctor.svg'
import manyDoctors from '../../../style/icons/Doctors/manyDoctors.svg'
import searchIcon from '../../../style/icons/Shevron/searchIcon.svg'
import { bookConsultation, cancelConsultation, deleteConsultationDataIfPaid, getConsultationBookingDetails, getConsultationPrice, getInitialSpecializationStatus } from '../../../redux/consultations/consultations-operations'
import { createSelector } from 'reselect'
import { getCurrentUser, getUserCards } from '../../../redux/auth/auth-operations'
import { getAllSpecializations } from '../../../redux/specializations/specializations-operations'
import closestTime from '../../../style/icons/Time/closestTime.svg'
import exactTime from '../../../style/icons/Time/exactTime.svg'
import atChat from '../../../style/icons/consultations/atChat.svg'
import atChatActive from '../../../style/icons/consultations/atChatActive.svg'
import phoneCall from '../../../style/icons/consultations/phoneCall.svg'
import phoneCallActive from '../../../style/icons/consultations/phoneCallActive.svg'
import videoCall from '../../../style/icons/consultations/videoCall.svg'
import videoCallActive from '../../../style/icons/consultations/videoCallActive.svg'
import searchBlack from '../../../style/icons/Search/searchBlack.svg'
import { changeConsultationType, getDoctorPeriods, getDoctors, getDoctorsListBySearch, getRandomDoctor } from '../../../redux/doctors/doctors-operations'
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/uk'
import { addNewAddress, getAddresses } from '../../../redux/addresses/addresses-operations'
import { SimpleForm } from '../../components/formComponents/SimpleForm/SimpleForm'
import SimpleInput from '../../components/formComponents/SimpleInput/SimpleInput'
import { parserForm } from '../../../utils/parserForm'
import Spinner from '../../components/ui/Spinner/Spinner'
import hospital from '../../../style/icons/Hospital/hospital.svg'
import { getHospitalGroups, getHospitals } from '../../../redux/hospitals/hospitals-operations'
import AddressForm from '../../forms/AddressForm/AddressForm'
import { getConsultationMethod } from '../../../utils/consultationMethods'
import { useSelector } from 'react-redux'
import BookingSuccessComponent from '../../components/BookingSuccessComponent/BookingSuccessComponent'
import promocodeIcon from '../../../style/icons/Promocode/promocodes-icon.svg'
import creditCardIcon from '../../../style/icons/Promocode/credit-card.svg'
import PageHeader from '../../components/PageHeader/PageHeader'
import StepHeaderComponent from '../AnalysesBooking/StepHeaderComponent/StepHeaderComponent'
import { getStepCount, getTypeTitle } from '../../../utils/helpers'
import { getIsCurrentCardSubscribed } from '../../../utils/sort'
import { getSubscriptionsInfo } from '../../../redux/subscription/subscription-selectors'
import { addNotification } from '../../../redux/popup/popup-actions'
import {v4} from 'uuid'
import { resetIsEmpty } from '../../../redux/doctors/doctors-actions'
import PriceComponent from '../../components/PriceComponent/PriceComponent'
import { getBookedConsultationId, getBookingDetails, getCurrentConsultationPrice, getFondySrc, getInitialStatusSelector, getIsDisabledBookingHome, getIsNeesToPay, getLoading } from '../../../redux/consultations/consultations-selectors'
import { resetFondyUrl } from '../../../redux/consultations/consultations-actions'
import { getConsultationBonusesSelector, getIsRegistered, getPatientBonusesSelector } from '../../../redux/fishka/fishka-selectors'
import { calculateConsultationBonuses, getPatientBonuses } from '../../../redux/fishka/fishka-operations'
import ModalWindow from '../../components/ModalWindow/ModalWindow'
import sync from '../../../style/icons/sync.svg'
import InputField from '../../components/InputField/InputField'
import { debounce } from 'lodash'
import { useCallback } from 'react'
import SmsCodeComponent from '../../components/SmsCodeComponent/SmsCodeComponent'
import fishkaLogo from '../../../style/icons/Fishka/fiskaMainCircle.svg'
import FishkaMain from '../../components/FishkaMain/FishkaMain'
import { openFishka, moveDirectlyToRegister } from '../../../redux/fishka/fishka-actions'
import { getHospitalGroupsSelector, getHospitalsLoading, getHospitalsSelector } from '../../../redux/hospitals/hospitals-selectors'
import { filterHospitals } from '../../../redux/hospitals/hospitals-actions'
import { getAddressesSelector } from '../../../redux/addresses/addresses-selectors'
import { getSpecializationsSelector } from '../../../redux/specializations/specializations-selectors'
import useUpdateEffect from '../../../hooks/useUpdateEffect'
import { getPeriodsLoading, getPeriodsSelector, getRandomDoctorSelector } from '../../../redux/doctors/doctors-selectors'
import TextPopover from '../../components/TextPopover/TextPopover'
import TitleComponent from '../../components/TitleComponent/TitleComponent'

const codeFishkaValidation = (value) => {
  return /\d{6}/.test(value)
    ? undefined
    : 'не меньше 6 цифр'
}

const ConsultationBooking = ({
  user = {},
  getConsultationBookingDetails,
  items,
  handleSubmit,
  bookingDetails,
  formValues = {},
  getAllSpecializations,
  getUserCards,
  changeConsultationType,
  userCards,
  bookConsultation,
  loading,
  fondySrc,
  isNeedToPay,
  subscription,
  resetIsEmpty,
  bookedConsultationId,
  getConsultationPrice,
  resetFondyUrl,
  cancelConsultation,
  consultationPrice,
  calculateConsultationBonuses,
  consultationBonuses,
  openFishka,
  getInitialSpecializationStatus,
  initialStatuses,
  randomDoctor,
  ...rest
}) => {
  const history = useHistory()
  const [formStep, setFormStep] = useState(1)
  const [type, setType] = useState('')
  const [paymentType, setPaymentType] = useState(null)
  const [specialization, setSpecialization] = useState({})
  const [stepMainDone, setStepMainDone] = useState(false)
  const [stepDoctorDone, setStepDoctorDone] = useState(false)
  const [stepTimeDone, setStepTimeDone] = useState(false)
  const [stepAddressDone, setStepAddressDone] = useState(false)
  const [stepOnlineTypesDone, setStepOnlineTypesDone] = useState(false)
  const [stepHospitalDone, setStepHospitalDone] = useState(false)
  const [stepConfirmationDone, setStepConfirmationDone] = useState(false)
  const [step5Done, setStep5Done] = useState(false)
  const [fishkaModalOpen, setFishkaModalOpen] = useState(false)
  const [smsLoading, setSmsLoading] = useState(false)
  const [fishkaCode, setFishkaCode] = useState('')
  const [bookingButtonLoading, setBookingButtonLoading] = useState(false)
  const params = useParams()
  const { t } = useTranslation()
  console.log('formValues', formValues);

  useEffect(() => {
    if (formStep < 7) {
      resetFondyUrl()
    }
  }, [formStep])

  useEffect(() => {
    return () => {
      resetFondyUrl()
      rest.reset()
    }
  }, [])

  useEffect(() => {
    resetIsEmpty()
  }, [type])
  
  useEffect(() => {
    if (formValues.type?.value === 0) {
      setType('online')
    }
    if (formValues.type?.value === 1) {
      setType('home')
    }
    if (formValues.type?.value === 2) {
      setType('hospital')
    }
    
  }, [formValues])

  useEffect(() => {
    getUserCards()
    getConsultationBookingDetails(params.id)
    getAllSpecializations()
  }, [])

  useEffect(() => {
    if (items) {
      setSpecialization(items.find(({ id }) => id == params.id))
    }
  }, [items])

  useEffect(() => {
    if (formStep === 1) {
      changeConsultationType()
      rest.reset()
    }
  }, [formStep])

  console.log(formValues);

  useEffect(() => {
    if ((formValues.type || formValues.type === 0) && formValues.card) {
      setStepMainDone(true)
    } else {
      setStepMainDone(false)
    }
    if (formValues.doctor) {
      setStepDoctorDone(true)
    } else {
      setStepDoctorDone(false)
    }
    if (formValues.time) {
      setStepTimeDone(true)
    } else {
      setStepTimeDone(false)
    }
    if (formValues.typeOnline?.value.toString()) {
      setStepOnlineTypesDone(true)
    } else {
      setStepOnlineTypesDone(false)
    }
    if (formValues.address) {
      setStepAddressDone(true)
    } else {
      setStepAddressDone(false)
    }
    if (formValues?.hospital) {
      setStepHospitalDone(true)
    } else {
      setStepHospitalDone(false)
    }
  }, [formValues])

  useUpdateEffect(() => {
    if (formValues?.card?.id && formValues?.type && !formValues?.fishkaCode && !formValues?.fishkaAmount) {
      getConsultationPrice({
        cardId: formValues.card?.id,
        specialtyId: specialization?.id,
        type: formValues.type?.value,
        doctorId: formValues.doctor?.id || randomDoctor?.id,
        date: formValues.time?.start,
        districtId: formValues?.address?.district
      })
    }
  }, [formValues])

  useUpdateEffect(() => {
    if (specialization?.id) {
      getInitialSpecializationStatus(specialization.id)
    }
  }, [specialization?.id])

  console.log(formValues);

  const handleCancelConsultation = () => {
    cancelConsultation(bookedConsultationId)
  }

  const handleCloseFishkaModal = () => {
    setFishkaModalOpen(false)
  }

  const sendFishkaCode = (initPay = true) => {
    if (initPay) {
      setSmsLoading(true)
      setFishkaModalOpen(true)
    }
    calculateConsultationBonuses({
      specialty: params.id,
      type: formValues.type.value,
      card: formValues.card.id,
      doctor: formValues.doctor.id,
      date: formValues.time.start,
    },
      {
        bonuses: {
          fishka: {
            amount: formValues.fishkaAmount,
            initPay: initPay
          }
        }
      })
      .then(() => {
        setSmsLoading(false)
      })
  }

  const formSubmit = () => {
    if(paymentType === 2) setSmsLoading(true)
    const consultationMethod = formValues.typeOnline
      ? getConsultationMethod(formValues.typeOnline.value)
      : getConsultationMethod(type)
    
    let requestBody = {}

    switch (type) {
      case 'home':
        requestBody = {
          appointment: {
            method: consultationMethod,
            address: {
              ...formValues.address,
              province: +formValues.address.province,
              district: +formValues.address.district,
            },
            doctor: formValues?.doctor?.id || randomDoctor.id,
            start: formValues.time.start,
            patientCard: formValues.card.id,
            paymentType: 1,
          }
        }
        delete requestBody.appointment.address.provinceLabel;
        delete requestBody.appointment.address.districtLabel;
        break;
      case 'hospital':
        requestBody = {
          appointment: {
            method: consultationMethod,
            hospital: formValues.hospital.id,
            doctor: formValues.doctor.id,
            integrationSeance: formValues.time.id,
            patientCard: formValues.card.id,
            paymentType: 1
          }
        }
        break;
      case 'online':
        if (paymentType === 0 || !paymentType) {
          requestBody = {
            appointment: {
              method: consultationMethod,
              doctor: formValues.doctor.id,
              start: formValues.time.start,
              patientCard: formValues.card.id,
              paymentType: 0,
            }
          }
        }
        if (paymentType === 2) {
          requestBody = {
            appointment: {
              method: consultationMethod,
              doctor: formValues.doctor.id,
              start: formValues.time.start,
              patientCard: formValues.card.id,
              paymentType: 0,
            },
            bonuses: {
              fishka: {
                amount: formValues.fishkaAmount,
                authCode: fishkaCode,
              }
            }
          }
        }
        if (paymentType === 1) {
          requestBody = {
            appointment: {
              method: consultationMethod,
              doctor: formValues.doctor.id,
              start: formValues.time.start,
              patientCard: formValues.card.id,
              paymentType: 1,
              promocode: formValues.promocode,
            }
          }
        }
        break;
      default:
        return
    }
    setBookingButtonLoading(true)
    bookConsultation(specialization.id, requestBody)
      .then(() => {
        if (paymentType === 2) {
          setSmsLoading(false)
          setFishkaModalOpen(false)
          setFormStep(prev => prev + 1)
        }
        if (paymentType === 1) {
          setFormStep(prev => prev + 1)
        }
        if (type === "home") {
          setFormStep(prev => prev + 1)
        }
      })
      .catch((e) => {
        setFormStep(10)
        rest.reset()
      })
      .finally(() => setBookingButtonLoading(false))
  }

  // const isEnabled = type && initialStatuses && initialStatuses[type].enabled
  const withoutHome = history.location.pathname.includes('limited')

  return (<>
    <div className='bookingPageContainer'>
      <TitleComponent
        customIcon={specialization?.imageUrl}
        noIconColor
        title={specialization?.title}
        // marginTop={20}
      />
      {/* <PageHeader
        title={specialization?.title}
        image={specialization?.imageUrl}
        withPrevious
      /> */}
      <div className='bookingContentContainer'>
        <div className={`animationContainer ${(formStep === 7 && isNeedToPay && (paymentType === 0 || paymentType === 1 || paymentType === 2)) ? "noPadding" : ''}`}>
          <div className={`bookingContent ${formStep === 7 ? 'noOverflow' : ''}`}>
            {formStep === 1 && (
              <BookingStep1
                type={type}
                withoutHome={withoutHome}
                formStep={formStep}
                setFormStep={setFormStep}
                formValues={formValues}
                t={t}
                bookingDetails={bookingDetails}
                userCards={userCards}
                stepMainDone={stepMainDone}
                subscription={subscription}
              />
            )}
            {type === 'online' && (<>
              {formStep === 2 && (
                <BookingStepDoctor
                  specialization={specialization}
                  formStep={formStep}
                  type={type}
                  setFormStep={setFormStep}
                  subscription={subscription}
                  stepDoctorDone={stepDoctorDone}
                  formValues={formValues}
                />
              )}
              {formStep === 3 && (
                <BookingStepTime
                  type={type}
                  formStep={formStep}
                  setFormStep={setFormStep}
                  stepTimeDone={stepTimeDone}
                  formValues={formValues}
                  specialization={specialization}
                />
              )}
              {formStep === 4 && (
                <BookingStepOnlineTypes
                  formStep={formStep}
                  setFormStep={setFormStep}
                  type={type}
                  stepOnlineTypesDone={stepOnlineTypesDone}
                  t={t}
                />
              )}
              {formStep === 5 && (
                <BookingStepConfirmation
                  consultationPrice={consultationPrice}
                  formStep={formStep}
                  formValues={formValues}
                  formSubmit={formSubmit}
                  specialization={specialization}
                  // isCardSubscribed={isCardSubscribed}
                  type={type}
                  isNeedToPay={isNeedToPay}
                  setFormStep={setFormStep}
                  step5Done={step5Done}
                  subscription={subscription}
                  bookingButtonLoading={bookingButtonLoading}
                  t={t}
                />
              )}
              {consultationPrice.price < 1 && formStep === 6 && (
                <LoadingComponent loading={loading}>
                  <BookingSuccessComponent
                    t={t}
                    text={t("onlineBookingSuccess")}
                  />
                </LoadingComponent>
              )}
              {consultationPrice.price >= 1 && (<>
                {formStep === 6 && (
                  <BookingStepPaymentType
                    loading={loading}
                    consultationBonuses={consultationBonuses}
                    paymentType={paymentType}
                    setPaymentType={setPaymentType}
                    formSubmit={formSubmit}
                    formValues={formValues}
                    formStep={formStep}
                    setFormStep={setFormStep}
                    sendFishkaCode={sendFishkaCode}
                    t={t}
                    type={type}
                  />
                )}
                {paymentType === 2 && (<>
                  {formStep === 7 && isNeedToPay && fondySrc && (
                    <BookingStepFondy
                      handleCancelConsultation={handleCancelConsultation}
                      formStep={formStep}
                      type={type}
                      bookedConsultationId={bookedConsultationId}
                      setFormStep={setFormStep}
                      fondySrc={fondySrc}
                    />
                  )}
                  {formStep === 8 && isNeedToPay && (
                    <BookingSuccessComponent
                      t={t}
                      text={t("onlineBookingSuccess")}
                    />
                  )}
                  {formStep === 7 && !isNeedToPay && (
                    <BookingSuccessComponent
                      t={t}
                      text={t("onlineBookingSuccess")}
                    />
                  )}
                </>)}
                {paymentType === 0 && (<>
                  {formStep === 7 && (
                    <BookingStepFondy
                      handleCancelConsultation={handleCancelConsultation}
                      formStep={formStep}
                      type={type}
                      bookedConsultationId={bookedConsultationId}
                      setFormStep={setFormStep}
                      fondySrc={fondySrc}
                    />
                  )}
                  {formStep === 8 && (
                    <BookingSuccessComponent
                      t={t}
                      text={t("onlineBookingSuccess")}
                    />
                  )}
                </>)}
                {paymentType === 1 && isNeedToPay && fondySrc && (<>
                  {formStep === 7 && (
                    <BookingStepFondy
                      handleCancelConsultation={handleCancelConsultation}
                      formStep={formStep}
                      type={type}
                      bookedConsultationId={bookedConsultationId}
                      setFormStep={setFormStep}
                      fondySrc={fondySrc}
                    />
                  )}
                  {formStep === 8 && (
                    <BookingSuccessComponent
                      t={t}
                      text={t("onlineBookingSuccess")}
                    />
                  )}
                </>)}
                {paymentType === 1 && formStep === 7 && !isNeedToPay && (<>
                  <BookingSuccessComponent
                    t={t}
                    text={t("onlineBookingSuccess")}
                  />
                </>)}
              </>)}
            </>)}
            {type === 'hospital' && (<>
              {formStep === 2 && (
                <BookingStepHospital
                  formStep={formStep}
                  stepHospitalDone={stepHospitalDone}
                  setFormStep={setFormStep}
                  specialization={specialization}
                  type={type}
                />
              )}
              {formStep === 3 && (
                <BookingStepDoctor
                  specialization={specialization}
                  formStep={formStep}
                  type={type}
                  setFormStep={setFormStep}
                  stepDoctorDone={stepDoctorDone}
                  formValues={formValues}
                />
              )}
              {formStep === 4 && (
                <BookingStepTime
                  type={type}
                  formStep={formStep}
                  setFormStep={setFormStep}
                  stepTimeDone={stepTimeDone}
                  formValues={formValues}
                  specialization={specialization}
                />
              )}
              {formStep === 5 && (
                <BookingStepConfirmation
                  formStep={formStep}
                  formValues={formValues}
                  formSubmit={formSubmit}
                  specialization={specialization}
                  type={type}
                  setFormStep={setFormStep}
                  step5Done={step5Done}
                  bookingButtonLoading={bookingButtonLoading}
                  t={t}
                />
              )}
              {formStep === 6 && (
                <BookingSuccessComponent
                  text={t("inClinicBookingSuccess")}
                />
              )}
            </>)}
            {type === 'home' && (<>
              {formStep === 2 && (
                <BookingStepAddress
                  // isEnabled={isEnabled}
                  formValues={formValues}
                  type={type}
                  t={t}
                  formStep={formStep}
                  setFormStep={setFormStep}
                  stepAddressDone={stepAddressDone}
                  setStepAddressDone={setStepAddressDone}
                />
                // <BookingStepDoctor
                //   specialization={specialization}
                //   formStep={formStep}
                //   type={type}
                //   setFormStep={setFormStep}
                //   stepDoctorDone={stepDoctorDone}
                //   formValues={formValues}
                // />
              )}
              {/* {formStep === 3 && (
                <BookingStepDoctor
                  specialization={specialization}
                  formStep={formStep}
                  type={type}
                  setFormStep={setFormStep}
                  stepDoctorDone={stepDoctorDone}
                  formValues={formValues}
                />
                // <BookingStepAddress
                //   type={type}
                //   t={t}
                //   formStep={formStep}
                //   setFormStep={setFormStep}
                //   stepAddressDone={stepAddressDone}
                //   setStepAddressDone={setStepAddressDone}
                // />
              )} */}
              {formStep === 3 && (
                <BookingStepTime
                  type={type}
                  formStep={formStep}
                  setFormStep={setFormStep}
                  stepTimeDone={stepTimeDone}
                  formValues={formValues}
                  specialization={specialization}
                />
              )}
              {formStep === 4 && (
                <BookingStepConfirmation
                  formSubmit={formSubmit}
                  formStep={formStep}
                  formValues={formValues}
                  specialization={specialization}
                  type={type}
                  setFormStep={setFormStep}
                  stepConfirmationDone={stepConfirmationDone}
                  bookingButtonLoading={bookingButtonLoading}
                  t={t}
                />
              )}
              {formStep === 5 && (
                <BookingSuccessComponent
                  text={t('atHomeBookingSuccess')}
                />
              )}
            </>)}
            {formStep === 10 && (
              <BookingSuccessComponent
                t={t}
                text={t("onlineBookingSuccess")}
                onClick={() => setFormStep(1)}
                error
              />
            )}
          </div>
        </div>
        <SpecializationInfo
          t={t}
          specialization={specialization}
        />
      </div>
    </div>
    <ModalWindow open={fishkaModalOpen} confirmMode handleCloseModal={handleCloseFishkaModal}>
      <SmsCodeComponent
        setCode={setFishkaCode}
        sendCode={() => sendFishkaCode()}
        smsLoading={smsLoading}
        handleClose={() => setFishkaModalOpen(false)}
        handleConfirm={formSubmit}
      />
      {/* <div className='fishkaCodeContainer'>
        <Field
          component={InputField}
          validate={codeFishkaValidation}
          className='fishkaCodeInput'
          name='fishkaCode'
          label={t('InputSmsCode')}
          placeholder={t("code")}
        />
        <div className='repeatContainer'>
          <div className='repeat'>{t("ResendCode")}</div>
          <img
            className={`${smsLoading ? 'repeatCodeImg' : ''}`}
            onClick={() => sendFishkaCode()}
            src={sync}
            alt="repeat code"
          />
        </div>
        <div className="buttonContainer">
          <Button
            primary
            disabled={rest.invalid}
            className='btn'
            text={t("confirmButton")}
            onClick={() => formSubmit()}
          />
        </div>
      </div> */}
    </ModalWindow>
  </>
  )
}

const BookingStepFondy = ({
  fondySrc,
  setFormStep,
  type,
  formStep,
  bookedConsultationId,
  handleCancelConsultation,
}) => {
  const { t } = useTranslation()
  const iframeRef = useRef()
  
  window.onmessage = function (event) {
    if (event.origin === "http://dobrodoc.com") {
      setFormStep(prev => prev + 1)
      deleteConsultationDataIfPaid(bookedConsultationId)
    }
  };

  return (
    <div className='paymentContainer'>
      <StepHeaderComponent
        onCancelConsultation={handleCancelConsultation}
        stepCount={getStepCount(type) + 1}
        formStep={formStep}
        setFormStep={setFormStep}
        title={t(getTypeTitle(type))}
      />
      <iframe
        ref={iframeRef}
        onLoad={(e) => console.log(e.target.src)}
        src={fondySrc}
        frameBorder="0"
        className='fondyContainer'
        title='fondySystem'
      >
      </iframe>
    </div>
  )
}

const ValidationInput = ({ input, placeholder, type, ...rest }) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    input.onChange(value)
  }, [value])

  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(`${+e.target.value}`)}
      {...rest}
    />
  )
}

const BookingStepPaymentType = ({
  formStep,
  setFormStep,
  formValues,
  formSubmit,
  setPaymentType,
  paymentType,
  type,
  sendFishkaCode,
  consultationBonuses,
  loading,
}) => {
  const contentRef = useRef()
  const balance = useSelector((state) => getPatientBonusesSelector(state))
  const consultationPrice = useSelector((state) => getCurrentConsultationPrice(state))
  const isRegistered = useSelector((state) => getIsRegistered(state))
  const dispatch = useDispatch()
  const [error, setError] = useState(null)
  const {t} = useTranslation()

  const handleResume = () => {
    if (paymentType === 1) {
      formSubmit()
      // setFormStep(prev => prev + 1)
    }
    if (paymentType === 0) {
      formSubmit()
      setFormStep(prev => prev + 1)
    }
    if (paymentType === 2 && isRegistered) {
      sendFishkaCode()
    }
  }
  
  const fishkaAmountValidation = (value) => {
    const error = t("fishkaError")
    if (value > balance) return error
    if (value < 0) return error
    if (value > consultationPrice?.price) return error
    if(value.includes('.')) return error
    return null
  }

  useEffect(() => {
    if (formValues.fishkaAmount) {
      debouncedSendCode(+formValues.fishkaAmount)
      setError(fishkaAmountValidation(formValues.fishkaAmount))
    }
  }, [formValues])

  const debouncedSendCode = useCallback(debounce((value) => {
    console.log(111);
    sendFishkaCode(false)
  }, 800), [])

  useEffect(() => {
    if (paymentType === 2) {
      contentRef.current.scrollTo({
        top: 99999,
        behavior: 'smooth'
      })
      dispatch(getPatientBonuses())
    }
  }, [paymentType])

  const fishkaBonusesCount = Number(formValues?.fishkaAmount) >= consultationPrice?.price
    ? (0).toFixed(2)
    : (consultationPrice?.price - +formValues?.fishkaAmount) / 10;
  
  console.log(+formValues?.fishkaAmount);
  return (
    <div className='bookingStepDoctor'>
      <StepHeaderComponent
        stepCount={getStepCount(type)}
        formStep={formStep}
        setFormStep={setFormStep}
        title={t("bookConsultationOnline")}
      />
      <div ref={contentRef} className='bookingStepContent'>
        <PaymentToggle
          isRegistered={isRegistered}
          setPaymentType={setPaymentType}
          paymentType={paymentType}
          t={t}
        />
        {paymentType === 1 && (
          <div className='inputPromocodeBox'>
            <Field
              name='promocode'
              component='input'
              placeholder={t("insertPromo")}
              className='inputPromocode'
              type="text"
            />
            {loading && (
              <div className="spinnerBox">
                <Spinner width={40} height={40}/>
              </div>
            )}
          </div>
        )}
        {paymentType === 2 && (
          <div className='fishkaPaymentBox'>
            <div className='fishkaPaymentInfo'>
              <div className='infoText'>{ t("fishkaAvailability")}</div>
              <div className='infoAmount'>{balance?.toFixed(2)}</div>
            </div>
            <div className='fishkaPaymentInfo'>
              <img className='fishkaIcon' src={fishkaLogo} alt="fishka" />
              <div className='infoText'>{ t("fishkaResetPoints")}</div>
              {error && (
                <div className={`fishkaAmountError`}>{error}</div>
              )}
              <Field
                name='fishkaAmount'
                // value={fom}
                component={ValidationInput}
                className='fishkaPointInput'
                max={consultationPrice || balance}
                min={0}
                type="number"
                step="1"
                pattern="\d*"
              />
            </div>
            <div className='fishkaPaymentInfo'>
              <div className='infoText'>{ t("fishkaWillReceive")}</div>
              <div className='infoAmount'>{
                isNaN(fishkaBonusesCount) ? (0).toFixed(2) : Number(fishkaBonusesCount) && fishkaBonusesCount.toFixed(2) || (0).toFixed(2)
              }
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='bookingStepButton'>
        <Button
          primary
          disabled={(paymentType === 1 && !formValues.promocode) || loading || error}
          className='btn'
          text={`${paymentType === 1 ? t("bookConsultation") : paymentType === 2 ? t("confirmButton") : t("Next")}`}
          onClick={handleResume}
        />
      </div>
    </div>
  )
}

const PaymentToggle = ({
  setPaymentType,
  paymentType,
  t,
  isRegistered,
}) => {
  const dispatch = useDispatch()
  return (
    <div className='paymentToggleContainer'>
      <div
        onClick={() => setPaymentType(1)}
        className={`promocodeType ${paymentType === 1 ? 'choosen' : ''}`}
      >
        <img src={paymentType === 1 ? iconChecked : promocodeIcon} alt="promocodeType" />
        <div className='paymentTitle'>{t("byPromocode")}</div>
      </div>
      <div
        onClick={() => setPaymentType(0)}
        className={`cardType ${paymentType === 0 ? 'choosen' : ''}`}
      >
        {paymentType === 0 ? (
          <img className='checkedIcon' src={iconChecked} alt="promocodeType" />
        ) : (
          <div className='cardImageBox'>
            <img src={paymentType === 0 ? iconChecked : creditCardIcon} alt="promocodeType" />
          </div>
        )}
        <div className='paymentTitle'>{t("byCard")}</div>
      </div>
      <div
        onClick={() => {
          if (!isRegistered) {
            dispatch(moveDirectlyToRegister(true))
            dispatch(openFishka())
            return
          }
          setPaymentType(2)
        }}
        className={`promocodeType ${paymentType === 2 ? 'choosen' : ''}`}
      >
        <img src={paymentType === 2 ? iconChecked : fishkaLogo} alt="promocodeType" />
        <div className='paymentTitle'>{t("byFishka")}</div>
      </div>
    </div>
  )
}

// const BookingStepFishka = ({
//   formStep,
//   type,
//   setFormStep,
//   stepFishkaDone,
// }) => {
//   const { t } = useTranslation()
  
//   return (
//     <div className='bookingStepDoctor'>
//       <StepHeaderComponent
//         stepCount={getStepCount(type)}
//         formStep={formStep}
//         setFormStep={setFormStep}
//         title={t(getTypeTitle(type))}
//       />
//         <div className='bookingStepContent'>
//           <div className="chooseDoctor">{t("InputSmsCode")}</div>
//         <Field
//           сomponent='input'
//           className='fishkaCodeInput'
//           required
//         />
//         </div>
//       <div className='bookingStepButton'>
//         <Button
//           primary
//           disabled={!stepFishkaDone}
//           className='btn'
//           text={t("Next")}
//           onClick={() => {
//             setFormStep(prev => prev + 1)
//           }}
//         />
//       </div>
//     </div>
//   )
// }

const mapStateToPropsHosp = (state) => ({
  hospitalGroups: getHospitalGroupsSelector(state),
  hospitals: getHospitalsSelector(state),
  hospitalsLoading: getHospitalsLoading(state),
})

const mapDispatchToPropsHosp = {
  getHospitalGroups,
  getHospitals,
  filterHospitals,
}
const BookingStepHospital = connect(mapStateToPropsHosp, mapDispatchToPropsHosp)(({
  formStep,
  hospitalGroups,
  hospitals,
  getHospitalGroups,
  getHospitals,
  setFormStep,
  specialization,
  stepHospitalDone,
  type,
  hospitalsLoading,
  filterHospitals,
}) => {
  const [loading, setLoading] = useState(true)
  const [showHospitals, setShowHospitals] = useState(false)
  const [groupId, setGroupId] = useState(null)
  const [query, setQuery] = useState('')
  const { t } = useTranslation()
  
  useEffect(() => {
    getHospitalGroups(specialization.id)
      .then(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (groupId) {
      getHospitals(groupId, specialization.id)
    }
  }, [groupId])

  const debouncedSearchHospital = useCallback(debounce((query) => {
    filterHospitals(query)
  }, 200), [])

  useEffect(() => {
    if (query) {
      debouncedSearchHospital(query)
    }
    if (!query && groupId) {
      getHospitals(groupId, specialization.id)
    }
  }, [query, groupId, specialization])



  return (
    <div className='bookingStepDoctor'>
      <StepHeaderComponent
        stepCount={getStepCount(type)}
        formStep={formStep}
        setFormStep={setFormStep}
        title={t(getTypeTitle(type))}
      />
      <LoadingComponent loading={loading}>
        <div className='bookingStepContent'>
          <div className="chooseDoctor">{t("selectClinic")}</div>
          <Field
            name='hospital'
            component={HospitalToggle}
            hospitalGroups={hospitalGroups}
            hospitalsLoading={hospitalsLoading}
            setQuery={setQuery}
            hospitals={hospitals}
            setShowHospitals={setShowHospitals}
            showHospitals={showHospitals}
            setGroupId={setGroupId}
            query={query}
            required
          />
        </div>
      </LoadingComponent>
      <div className='bookingStepButton'>
        <Button
          primary
          disabled={!stepHospitalDone}
          className='btn'
          text={t("Next")}
          onClick={() => {
            setFormStep(prev => prev + 1)
          }}
        />
      </div>
    </div>
  )
})

export const HospitalToggle = ({
  input: { onChange },
  hospitalGroups,
  hospitals,
  setShowHospitals,
  showHospitals,
  setGroupId,
  hospitalsLoading,
  setQuery,
  query,
}) => {
  const itemRef = useRef()
  const hospitalBoxRef = useRef()
  const [selected, setSelected] = useState(null)
  const { t } = useTranslation()
  const timeoutId = useRef(null)
    
  useEffect(() => {
    onChange(selected)
  }, [selected])

  useEffect(() => {
    if (showHospitals && itemRef.current && hospitalBoxRef.current) {
      timeoutId.current = setTimeout(() => {
        console.log(hospitalBoxRef.current);
        hospitalBoxRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, 300)
    }
    return () => {
      clearTimeout(timeoutId.current)
    }
  }, [showHospitals, itemRef, hospitalBoxRef])

  return (
    <div style={{overflow: "auto"}}>
    <div className='hospitalContainer'>
      {hospitalGroups && hospitalGroups.map(hosp => {
        return (<>
          <div
            key={hosp.id}
            ref={itemRef}
            onClick={() => {
            setGroupId(hosp.id)
            setShowHospitals(prev => !prev)
            }}
            className='hospitalItem'
          >
            <img className='hospitalImage' src={hospital} alt="hospital" />
            <div className='textContainer'>
              <div className='clinicTitle'>{hosp.title}</div>
              <div className='clinicCounts'>{t("branchesCount")} {hosp.hospitalCount}</div>
            </div>
            <img
              preload='auto'
              className={`showMoreImg ${showHospitals ? 'rotate' : ''}`}
              style={{ marginLeft: "auto" }}
              src={previousIcon}
              alt="showMore"
            />
          </div>
          {showHospitals && (
            <div ref={hospitalBoxRef} className='hospitalListBox'>
              <div className='inputBox'>
                <input
                  type="text"
                  onChange={(e) => setQuery(e.target.value)}
                  className='hospitalInput'
                  placeholder={t("enterClinicAddress")}
                />
                <img className='searchIcon' src={searchIcon} alt="hospital" />
              </div>
              <LoadingComponent loading={hospitalsLoading} width={40} height={40}>
                {query && hospitals && hospitals.length === 0 && (
                  <div className='notFoundBox'>
                    <img className='notFoundIcon' src={searchBlack} alt="search" />
                    <div className="notFoundText">{t("nothingFound")}</div>
                  </div>
                )}
                {hospitals && hospitals.map(item => {
                  return (
                    <div
                      key={item.id}
                      onClick={() => setSelected(item)}
                      className={`hospitaListItem ${selected?.id === item.id ? 'selected' : ''}`}
                    >
                      <img
                        className='hospItemImage'
                        src={selected?.id === item.id ? iconChecked : hospital}
                        alt=""
                      />
                      <div
                        className={`hospitalAddress ${selected?.id === item.id ? 'selected' : ''}`}
                      >{item.addressText}
                      </div>
                    </div>
                  )
                })}
              </LoadingComponent>
            </div>
          )}
        </>)
      })}
      </div>
      </div>
  )
}
export const LoadingComponent = ({children, loading, ...props}) => {
  return (<>
    { loading && (
      <div className='absoluteCenter'>
        <Spinner {...props}/>
      </div>
    )}
    {!loading && (<>
      {children}
    </>)}
  </>)
}

const mapStateToPropsAdr = (state) => ({
  // addresses: getAddressesSelector(state),
  isDisabledBookingAtHome: getIsDisabledBookingHome(state),
})
const mapDispatchToPropsAdr = {
  getAddresses,
  getRandomDoctor,
}
const BookingStepAddress = connect(mapStateToPropsAdr, mapDispatchToPropsAdr)(({
  formStep,
  setFormStep,
  isDisabledBookingAtHome,
  formValues,
  getAddresses,
  type,
  t,
}) => {
  const [loading, setLoading] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const params = useParams()
  const showFormRef = useRef()
  const scrollElemRef = useRef()
  const history = useHistory()

  useEffect(() => {
    getAddresses()
  }, [])

  useUpdateEffect(() => {
    if (isDisabledBookingAtHome) {
      history.push(`${history.location.pathname}/limited`)
    }
  }, [isDisabledBookingAtHome])

  useEffect(() => {
    if (showForm && showFormRef.current) {
      setTimeout(() => {
        document.querySelector('.addressFormContainer').scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, 300)
    }
  }, [showForm, showFormRef])

  const handleOnLimitedClick = () => {
    setFormStep(1)
  }

  return (
    <div className='bookingStepAddress'>
      <StepHeaderComponent
        stepCount={getStepCount(type)}
        formStep={formStep}
        setFormStep={setFormStep}
        title={t(getTypeTitle(type))}
      />
      <div ref={scrollElemRef} className='bookingStepContent'>
        <div className='chooseDoctor'>{t("enterYourAddress")}</div>
        <Field
          name='address'
          component={AddressForm}
          isDisabledBookingAtHome={isDisabledBookingAtHome}
          onClickLimited={handleOnLimitedClick}
          mainComponent
          // isEnabled={isEnabled}
          setShowForm={setShowForm}
          setLoading={setLoading}
          setFormStep={setFormStep}
        />
      </div>
    </div>
  )
})

export const ToggleAddress = ({
  showForm,
  addresses,
  input: { onChange, value },
  setShowForm,
  showFormRef,
  modeLaboratories,
}) => {
  const [selected, setSelected] = useState(value)
  const { t } = useTranslation()
  
  useEffect(() => {
    onChange(selected)
  }, [selected])

  return (
    <div className='doctorToggleContainer'>
      {addresses && addresses.map(adr => {
        return (
          <div
            key={adr.id}
            onClick={() => setSelected(adr)}
            className={`exactDocLabel ${selected?.id === adr.id ? 'randomTimeSelected' : ''}`}
          >
            <img
              preload='auto'
              style={{ marginRight: "15px", borderRadius: "50%", width: "50px" }}
              src={selected?.id === adr.id ? iconChecked : (modeLaboratories ? clinicIcon : manyDoctors)}
              alt="manyDoctor"
            />
            <div className='addressTitleContainer'>
              <div className={`addressTitle ${selected?.id === adr.id ? 'activeTitle' : ''}`}>
                {t("Address")}
              </div>
              <div className={`addressTitle ${selected?.id === adr.id ? 'activeTitle' : ''}`}>
                {t("cityShort")}{adr.city}, {t("streetShort")}{adr.thoroughfare}, {t("houseShort")}{adr.houseNumber}, {t("flatShort")}{adr.flatNumber}
              </div>
            </div>
          </div>
        )
      })}
      {!modeLaboratories && (
        <div
          ref={showFormRef}
          onClick={() => setShowForm(prev => !prev)}
          className={`exactDocLabel`}
        >
          <img
            preload='auto'
            style={{ marginRight: "15px", borderRadius: "50%", width: "50px" }}
            src={manyDoctors}
            alt="manyDoctor"
          />
          <div className={`doctorTitle`}>{t("enterYourAddress")}</div>
          <img
            preload='auto'
            className={`showMoreImg ${showForm ? 'rotate' : ''}`}
            style={{ marginLeft: "auto" }}
            src={previousIcon}
            alt="showMore"
          />
        </div>
      )}
    </div>
  )
}

const BookingStepConfirmation = ({
  formStep,
  setFormStep,
  formValues,
  specialization,
  consultationPrice,
  type,
  formSubmit,
  bookingButtonLoading,
  t,
}) => {
  const handleResume = () => {
    if (type === 'online' && consultationPrice.price >= 1) {
      setFormStep(prev => prev + 1)
      return
    }
    formSubmit()
    if (type === "home") return
    setFormStep(prev => prev + 1)
  }

  return (
    <div className='bookingStepConfirmation'>
      <StepHeaderComponent
        stepCount={getStepCount(type)}
        formStep={formStep}
        setFormStep={setFormStep}
        title={t("informationConfirm")}
      />
      <div className={`confirmationContainer ${type === 'online' ? 'lessHeight' : ''}`}>
        <div className='confirmationItem'>
          <div className='confirmationItemTitle'>{t("Medcard")}</div>
          <div className='confirmationCard'>
            <img
              width='50'
              height='50'
              style={{ marginRight: "15px", borderRadius: "50%", width: "50px" }}
              src={formValues.card.photoUrl ? formValues.card.photoUrl : patient_active}
              alt="card"
            />
            <div>
              <div
                className={`userTitle`}>
                {`${formValues.card.lastName} ${formValues.card.firstName}`}
              </div>
              <div className={`userRelation`}>
                {formValues.card.relationShip.title}
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div className='confirmationItem'>
            <div className='confirmationItemTitle'>{t("specialty")}</div>
            <div className='confirmationSpecialization'>
              <img src={specialization.imageUrl} alt="spec" />
              <div className='specializationName'>{specialization.title}</div>
            </div>
          </div>
          <div className='confirmationItem'>
            <div className='confirmationItemTitle'>{t("consultationType")}</div>
            <div className='confirmationSpecialization'>
              <img src={formValues.typeOnline?.icons[0] || formValues.type.icons[0]} alt="type" />
              <div className='specializationName'>{formValues.typeOnline?.label || formValues.type.label}</div>
            </div>
          </div>
        </div>
        <div className='confirmationItem'>
          <div className='confirmationItemTitle'>{t("time")}</div>
          <div className='confirmationCard'>
            <img
              style={{ marginRight: "15px", borderRadius: "50%", width: "50px" }}
              src={closestTime}
              alt="card"
            />
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <div
                className={`userTitle`}>
                {moment(formValues.time.start).format("DD MMMM")}
              </div>
              <div className={`userTime`}>
                {moment(formValues.time.start).format("HH:mm")}
              </div>
            </div>
          </div>
        </div>
        {type !== 'home' && (
          <div className='confirmationItem'>
            <div className='confirmationItemTitle'>{t("doctor")}</div>
            <div className='confirmationCard'>
              <img
                width='50'
                height='50'
                style={{ marginRight: "15px", borderRadius: "50%", width: "50px" }}
                src={formValues.doctor.employee.photoUrl || oneDoctor}
                alt="card"
              />
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <div className={`userTime`}>
                  {formValues.doctor.employee.fullName}
                </div>
              </div>
            </div>
          </div>
        )}
        {type === 'home' && (
          <div className="confimationItem">
            <div className='confirmationItemTitle'>{t("address")}</div>
            <div className="homeConfirmBox">
              <div className="addressText"><span className='addressTitle'>{t("city")}: </span>{formValues.address.provinceLabel}</div>
              <div className="addressText"><span className='addressTitle'>{t("district")}: </span>{formValues.address.districtLabel}</div>
              <div className="addressText"><span className='addressTitle'>{t("street")}: </span>{formValues.address.thoroughfare}</div>
              <div className="addressText"><span className='addressTitle'>{t("building")}: </span>{formValues.address.houseNumber}</div>
              <div className="addressText"><span className='addressTitle'>{t("EntranceNumber")}: </span>{formValues.address.entrance}</div>
              <div className="addressText"><span className='addressTitle'>{t("Stage")}: </span>{formValues.address.floor}</div>
              <div className="addressText"><span className='addressTitle'>{t("Flat")}: </span>{formValues.address.flatNumber}</div>
              {formValues.address?.comment && (
                <div className="addressText"><span className='addressTitle'>{t("codeOrNote")}: </span>{formValues.address.comment}</div>
              )}
            </div>
          </div>
        )}
        {type === 'hospital' && (
          <div className='confirmationItem'>
            <div className='confirmationItemTitle'>{t("Clinic")}</div>
            <div className='confirmationCard'>
              <img
                style={{ marginRight: "15px", borderRadius: "50%", width: "50px" }}
                src={hospital}
                alt="card"
              />
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <div className={`userTime`}>
                  {formValues.hospital.addressText}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {(type === 'online' || type === 'home') && (
        <PriceComponent
          isConfirmationStep={true}
          isFishkaNeeded={type === 'online' ? true : false}
        />
      )}
      <div className='bookingStepButton'>
        <Button
          primary
          className='btn'
          text={t("bookConsultation")}
          onClick={handleResume}
          buttonLoading={bookingButtonLoading}
        />
      </div>
    </div>
  )
}
const BookingStepOnlineTypes = ({
  formStep,
  setFormStep,
  stepOnlineTypesDone,
  type,
  t,
}) => {
  const AT_CHAT = 'atChat';
  const PHONE_CALL = 'phoneCall';
  const VIDEO_CALL = 'videoCall';

  return (
     <div className='bookingStepDoctor'>
      <StepHeaderComponent
        stepCount={getStepCount(type)}
        formStep={formStep}
        setFormStep={setFormStep}
        title={t(getTypeTitle(type))}
      />
      <div className='bookingStepContent'>
        <div className="chooseDoctor">{t("selectConsultationType")}</div>
        <Field
          name='typeOnline'
          component={TypeToggle}
          required
          options={[
            { label: t("optionsServiceTypeInChat"), icons: [atChat, atChatActive], value: AT_CHAT },
            { label: t("optionsServiceTypeCall"), icons: [phoneCall, phoneCallActive], value: PHONE_CALL },
            { label: t("optionsServiceTypeInVideo"), icons: [videoCall, videoCallActive], value: VIDEO_CALL },
          ]}
        />
      </div>
      <div className='bookingStepButton'>
        <Button
        primary
        disabled={!stepOnlineTypesDone}
        className='btn'
        text={`${t('Next')}`}
        onClick={() => setFormStep(prev => prev + 1)}
      />
      </div>
    </div>
  )
}

const mapStateToProps3 = (state) => ({
  periods: getPeriodsSelector(state),
})
const mapDispatchToProps3 = {
  getDoctorPeriods,
  getRandomDoctor,
}

const BookingStepTime = connect(mapStateToProps3, mapDispatchToProps3)(({
  formStep,
  setFormStep,
  stepTimeDone,
  formValues,
  specialization,
  getDoctorPeriods,
  periods,
  getRandomDoctor,
  type,
}) => {
  const [showTimeList, setShowTimeList] = useState(false)
  const [selected, setSelected] = useState(null)
  const params = useParams()
  const EXACT_TIME = 'exact'
  const CLOSEST_TIME = 'closest'
  const { t } = useTranslation()
  
  useEffect(() => {
    if (formValues.doctor && specialization.id) {
      getDoctorPeriods(formValues.doctor.id, specialization.id, type, formValues?.hospital?.id ?? null, formValues?.address?.district)
    }
  }, [formValues.doctor])

  useEffect(() => {
    if (type === 'home' && formValues?.address?.district) {
      getRandomDoctor(params.id, type, null, formValues?.address?.district)
    }
  }, [formValues?.address?.district])

  return (
    <div className='bookingStepDoctor'>
      <StepHeaderComponent
        stepCount={getStepCount(type)}
        formStep={formStep}
        setFormStep={setFormStep}
        title={t(getTypeTitle(type))}
      />
      <div className='bookingStepContent'>
        <div className="chooseDoctor">{t("selectConvenientTime")}</div>
        <Field
            name='time'
            component={TimeToggle}
            setShowTimeList={setShowTimeList}
            showTimeList={showTimeList}
            selected={selected}
            periods={periods}
            setSelected={setSelected}
            CLOSEST_TIME={CLOSEST_TIME}
            required
          />
      </div>
      {(type === 'online' || type === 'home') && (
        <PriceComponent/>
      )}
      <div className='bookingStepButton'>
        {showTimeList && (
          <Button
            primary
            className='btn'
            text={t("confirmButton")}
            onClick={() => {
              setShowTimeList(false)
              setSelected(EXACT_TIME)
            }}
          />
        )}
        {!showTimeList && (
          <Button
            primary
            disabled={!stepTimeDone}
            className='btn'
            text={t("Next")}
            onClick={() => setFormStep(prev => prev + 1)}
            // buttonLoading={periodsLoading}
          />
        )}
      </div>
    </div>
  )
})

const TimeToggle = ({
  input: { onChange },
  setShowTimeList,
  showTimeList,
  setSelected,
  selected,
  periods,
  CLOSEST_TIME,
}) => {
  const [dateSelected, setDateSelected] = useState(null)
  const [timeSelected, setTimeSelected] = useState(null)
  const timePickerRefs1 = useRef(null)
  const timePickerRefs2 = useRef(null)
  const [datePeriods, setDatePeriods] = useState([])
  const [timePeriods, setTimePeriods] = useState([])
  const [started, setStarted] = useState(0)
  const {t} = useTranslation()
  let lang = localStorage.getItem("i18nextLng").slice(0, 2)
  if(lang === 'ua') lang = 'uk'
  
  moment.locale(lang)
  
  useEffect(() => {
    onChange(periods && periods.find(el => moment(el.start).format("DD MMM HH:mm") === `${dateSelected} ${timeSelected}`))
  }, [dateSelected, timeSelected])

  useEffect(() => {
    if (periods) {
      const newArray = periods.map(el => moment(el.start).format("DD MMM"))
      setDatePeriods([...new Set(newArray)])
    }
  }, [periods])

  useEffect(() => {
    if (!showTimeList) {
      setStarted(0)
    }
    if (showTimeList) {
      setTimeout(() => {
        calculateForStart(timePickerRefs1.current)
        calculateForStart(timePickerRefs2.current)
      }, 0)
    }
  }, [showTimeList])

  useEffect(() => {
    if (periods && dateSelected && datePeriods) {
      const exactDayTimes = periods
        .filter(el => moment(el.start).format('DD MMM') === (!started ? moment(periods[0].start).format('DD MMM') : dateSelected))
        .map(el => moment(el.start).format("HH:mm"))
      setTimePeriods(exactDayTimes)
    }
  }, [periods, datePeriods, dateSelected, started])

  if (!periods) return null


  function scrollTop1to30px(target, childY, parentY) {
    const deltaDown = childY - parentY
    const deltaUp = parentY - childY
    if (childY > parentY) {
      target.scrollTop += deltaDown
    } else {
      target.scrollTop -= parentY - (childY+20)
    }
  }

  function calculatePosition(e) {

    const { target } = e;
    
    [...target.children].map((child, i, arr) => {
      const parentY = target.getBoundingClientRect().y;
      const centerParentY = parentY + 79;
      const childY = child.getBoundingClientRect().y;
      
      let nextChildY = 0
      child.onclick = () => {
        scrollTop1to30px(target, childY, centerParentY)
      }
      if (arr[i + 1]) {
        nextChildY = arr[i + 1].getBoundingClientRect().y
      }

      if (centerParentY > childY && centerParentY < nextChildY) {
        child.classList.add('isInCenter')
        if (target.id === 'date') {
          setDateSelected(child.textContent)
        }
        if (target.id === 'time') {
          setTimeSelected(child.textContent)
        }
      } else {
        child.classList.remove('isInCenter')
      }
    })
  }

  const calculateForStart = (target) => {
    const elem = target.children[1]
    elem.classList.add('isInCenter')
    if (target.id === 'date') {
      setDateSelected(elem.textContent)
      timePickerRefs1.current.scrollTo({top: 1, behavior: "smooth"})
      setStarted(1)
    }
    if (target.id === 'time') {
      setTimeSelected(elem.textContent)
      timePickerRefs2.current.scrollTo({top: 1, behavior: "smooth"})
      if (started === 1) {
        setStarted(2)
      }
    }
  }

  const exactTimeText = (dateSelected && timeSelected) ? `${dateSelected} ${t("at")} ${timeSelected}` : ""

  return (
    <>
      <div className='doctorToggleContainer'>
        <div
          onClick={() => {
            setSelected(CLOSEST_TIME)
            onChange(periods[0])
          }}
          className={`randomDocLabel ${selected === 'closest' ? 'randomDocSelected' : ''}`}
        >
          <img
            width='50'
            height='50'
            preload='auto'
            style={{ marginRight: "15px", borderRadius: "50%", width: "50px" }}
            src={selected === 'closest' ? iconChecked : closestTime}
            alt="oneDoctor"
          />
          <div style={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <div className={`doctorTitle ${selected === 'closest' ? 'activeTitle' : ''}`}>{t('nearestTime')}</div>
            <div className={`doctorTitle ${selected === 'closest' ? 'activeTitle' : ''}`}>{datePeriods[0]} в {moment(periods[0].start).format('HH:mm')}</div>
          </div>

        </div>
        <div
          onClick={() => {
            setShowTimeList(prev => !prev)
          }}
          // style={{height: "90px"}}
          className={`exactDocLabel ${selected === "exact" ? 'randomTimeSelected' : ''}`}
        >
          <img
            preload='auto'
            style={{ marginRight: "15px", borderRadius: "50%", width: "50px" }}
            src={selected === "exact" ? iconChecked : exactTime}
            alt="manyDoctor"
          />
          <div style={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <div className={`doctorTitle`}>{t("selectTime")}</div>
            <div className={`doctorTitle h30`}>{exactTimeText}</div>
          </div>
          <img
            preload='auto'
            className={`showMoreImg ${showTimeList ? 'rotate' : ''}`}
            style={{ marginLeft: "auto" }}
            // onClick={() => setShowDoctorList(true)}
            src={previousIcon}
            alt="showMore"
          />
        </div>
      </div>
      {/* MODAL SEARCH */}
      {showTimeList && (
        <div style={{display: "flex", flexDirection: "column"}}>
          <div className='timeContainer'>
            <div
              id='date'
              // onMouseEnter={(e) => started === 2 ? null :  calculateForStart(e)}
              // onLoadedData={calculateForStart}
              onScroll={calculatePosition}
              ref={timePickerRefs1}
              className="timePickerContainer"
            >
              <div style={{ height: "61px" }}></div>
              {datePeriods.map((period, i) => {
                return (
                  <div id={period.id} key={i} className='timePickerItem'>
                    {period}
                  </div>
                )
              })}
              <div style={{ height: "61px" }}></div>
            </div>
            <div
              id='time'
              onScroll={(e) => calculatePosition(e)}
              ref={timePickerRefs2}
              className="timePickerContainer"
            >
              <div style={{ height: "60px" }}></div>
              {timePeriods.map((period, i) => {
                return (
                  <div key={i+10} className='timePickerItem'>
                    {period}
                  </div>
                )
              })}
              <div style={{ height: "60px" }}></div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps2 = (state) => ({
  doctors: state?.doctors?.doctorsBySearch,
  randomDoctor: state?.doctors?.randomDoc,
  isGetDoctor: state?.doctors?.isGetDoctor,
  periodsLoading: getPeriodsLoading(state),
})
const mapDispatchToProps2 = {
  getDoctorsListBySearch,
  getRandomDoctor,
  getDoctorPeriods,
  getDoctors,
  addNotification,
}
// connect(mapStateToProps2, mapDispatchToProps2)(BookingStep2)

const BookingStepDoctor = connect(mapStateToProps2, mapDispatchToProps2)(({
  formStep,
  formValues,
  setFormStep,
  getDoctorPeriods,
  stepDoctorDone,
  getDoctorsListBySearch,
  getRandomDoctor,
  specialization,
  type,
  getDoctors,
  randomDoctor,
  doctors,
  isGetDoctor,
  addNotification,
  periodsLoading,
}) => {
  const [showDoctorList, setShowDoctorList] = useState(false)
  const [doctorQuery, setDoctorQuery] = useState('')
  const [chooseDoctor, setChooseDoctor] = useState(null)
  const [selected, setSelected] = useState(null)
  const RANDOM_DOC = 'random'
  const { t } = useTranslation()
  
  useEffect(() => {
    if (doctorQuery) {
      if (type === 'home') {
        getDoctorsListBySearch({
          specialty: specialization.id,
          type: formValues.type.value,
          district: formValues.address.district,
          fullName: doctorQuery,
        })
        return
      }
      getDoctorsListBySearch({
        specialty: specialization.id,
        type: formValues.type.value,
        fullName: doctorQuery,
      })
    }
  }, [doctorQuery])

  useEffect(() => {
    if (type === 'home') {
      getDoctors({
        specialty: specialization.id,
        type: formValues.type.value,
        district: formValues.address.district
      })
      console.log(1);
      return
    }
    getDoctors({
      specialty: specialization.id,
      type: formValues.type.value,
    })
    console.log(2);
  }, [type])

  useEffect(() => {
    if (!isGetDoctor) {
      addNotification({
        id: v4(),
        type: "ERROR",
        message: t("noDoctors")
      })
    }
  }, [isGetDoctor])


  useUpdateEffect(() => {
    if (chooseDoctor?.id) {
      getDoctorPeriods(chooseDoctor.id, specialization.id, type,  formValues?.hospital?.id ?? null, formValues?.address?.district)
    }
    if (chooseDoctor === RANDOM_DOC) {
      switch (type) {
        case "hospital":
          getRandomDoctor(specialization.id, type, formValues.hospital.id)
          break;
        // case "home":
        //   getRandomDoctor(specialization.id, type, null, formValues?.address?.district)
        //   break;
        case "online":
          getRandomDoctor(specialization.id, type)
          break;
      }
    }
  }, [chooseDoctor])

  // useEffect(() => {
  //   if (specialization.id) {
  //     if (formValues.hospital) {
  //       getRandomDoctor(specialization.id, type, formValues.hospital.id)
  //       return
  //     }
  //     if (type === 'home') {
  //       getRandomDoctor(specialization.id, type, null, formValues?.address?.district)
  //       return 
  //     }
  //    getRandomDoctor(specialization.id, type)
  //   }
  // }, [specialization, formValues.hospital, type])

  return (
    <div className='bookingStepDoctor'>
      <StepHeaderComponent
        stepCount={getStepCount(type)}
        formStep={formStep}
        setFormStep={setFormStep}
        title={t(getTypeTitle(type))}
      />
      <div className='bookingStepContent'>
        <div className="chooseDoctor">{t("selectDoctorTitle")}</div>
        <Field
          name='doctor'
          component={DoctorToggle}
          setShowDoctorList={setShowDoctorList}
          showDoctorList={showDoctorList}
          setSelected={setSelected}
          setDoctorQuery={setDoctorQuery}
          doctors={doctors}
          randomDoctor={randomDoctor}
          setChooseDoctor={setChooseDoctor}
          selected={selected}
          t={t}
          chooseDoctor={chooseDoctor}
          required
        />
      </div>
      {(type === 'online' || type === 'home') && (
        <PriceComponent/>
      )}
      <div className='bookingStepButton'>
        <Button
          type='button'
          primary
          disabled={!stepDoctorDone}
          className='btn'
          text={t("Next")}
          onClick={() => setFormStep(prev => prev + 1)}
          buttonLoading={periodsLoading}
        />
      </div>
    </div>
  )
})

const BookingStep1 = ({
  formStep,
  setFormStep,
  formValues,
  t,
  type,
  stepMainDone,
  userCards,
  withoutHome,
}) => {

  let options = [
    { label: t("online"), icons: [online, onlineActive], value: 0 },
    { label: t("inClinic"), icons: [clinic, clinicActive], value: 2 },
    { label: t("callOnHome"), icons: [home, homeActive], value: 1 },
  ]
  
  return (
    <div className='bookingStepCard'>
      <StepHeaderComponent
        t={t}
        stepCount={getStepCount(type)}
        formStep={formStep}
        setFormStep={setFormStep}
        title={t("selectConsultationType")}
      />
      <div className='bookingStepContent'>
        <Field
          name='type'
          component={TypeToggle}
          required
          options={withoutHome
            ? options.filter(el => el.value !== 1) 
            : options
          }
        />
        <div className='chooseCard'>{t("selectMedicalCard")}</div>
        <Field
          name='card'
          component={MedCardToggle}
          options={userCards}
          t={t}
          required
        />
      </div>
      {type === 'online' && formValues?.card && (
        <PriceComponent/>
      )}
      <div className='bookingStepButton'>
        <Button
          type='button'
          primary
          disabled={!stepMainDone}
          className='btn'
          text={t("Next")}
          onClick={() => setFormStep(prev => prev + 1)}
        />
      </div>
    </div>
  )
}

const SpecializationInfo = ({ specialization, t }) => {
  return (
    <div className='specInfoContainer'>
      <div className='specContentBox'>
        <div className='specInfoTitle'>{t("whatTreats")} {specialization.title}?</div>
        <div className='specInfo'>{specialization.description}</div>
      </div>
    </div>
  )
}
const DoctorToggle = ({
  t,
  input: { onChange },
  setShowDoctorList,
  showDoctorList,
  selected,
  setDoctorQuery,
  setChooseDoctor,
  doctors,
  randomDoctor,
  chooseDoctor,
  setSelected,
}) => {
  const RANDOM_DOC = 'randomDoctor'
  const EXACT_DOC = 'exactDoctor'
  
  useEffect(() => {
    if (selected === RANDOM_DOC) {
      onChange(randomDoctor)
    }
    if (selected === EXACT_DOC) {
      onChange(chooseDoctor)
    }
    if (!selected) {
      onChange(null)
    }
  }, [selected, chooseDoctor, randomDoctor])


  useEffect(() => {
    if (!showDoctorList) {
      setSelected(null)
    }
  }, [showDoctorList])

  return (<>
    <div className='doctorToggleContainer'>
      <div
        onClick={() => {
          setSelected(RANDOM_DOC)
          setChooseDoctor('random')
        }}
        className={`randomDocLabel ${selected === RANDOM_DOC ? 'randomDocSelected' : ''}`}
      >
        <img
          style={{ marginRight: "15px", borderRadius: "50%", width:"50px"}}
          src={selected === RANDOM_DOC ? iconChecked : oneDoctor}
          alt="oneDoctor"
        />
        <div
          className={`doctorTitle ${selected === RANDOM_DOC ? 'activeTitle' : ''}`}>
          {t("randomDoctor")}
        </div>

      </div>
      <div
        onClick={() => {
          setShowDoctorList(prev => !prev)
          setChooseDoctor(null)
          setSelected(null)
        }}
        style={{minHeight: "80px"}}
        className={`exactDocLabel ${selected === EXACT_DOC ? 'exactDocSelected' : ''}`}
      >
        <img
          style={{ marginRight: "15px", borderRadius: "50%", width:"50px"}}
          src={manyDoctors}
          alt="manyDoctor"
        />
        <div
          className={`doctorTitle`}>
          {t("selectDoctor")}
        </div>
        <img
          className={`showMoreImg ${showDoctorList ? 'rotate' : ''}`}
          style={{marginLeft: "auto"}}
          src={previousIcon}
          alt="showMore"
        />
      </div>
    </div>
    {showDoctorList && (
      <DoctorsListBySearch
        t={t}
        setDoctorQuery={setDoctorQuery}
        doctors={doctors}
        setSelected={setSelected}
        setChooseDoctor={setChooseDoctor}
        selected={selected}
        chooseDoctor={chooseDoctor}
      />
    )}
  </>)
}

const DoctorsListBySearch = ({
  doctors,
  setDoctorQuery,
  setSelected,
  selected,
  chooseDoctor,
  setChooseDoctor,
  t,
}) => {
  const EXACT_DOC = 'exactDoctor'

  return (
    <div className='doctorSearchContainer'>
      <div style={{ position: "relative", marginBottom: "20px" }}>
        <input
          onChange={(e) => {
            setDoctorQuery(e.target.value)
          }}
          type="text"
          className='doctorSearch'
          placeholder={t("enterDoctorName")}
        />
        <img className='searchIcon' src={searchIcon} alt="searchIcon" />
      </div>
      <div className='doctorInfoContainer'>
        {doctors && doctors.map((doctor) => {
          return (
            <div
              key={doctor.id}
              onClick={() => {
                // setSelectDoctor(doctor.employee)
                setSelected(EXACT_DOC)
                setChooseDoctor(doctor)
              }}
              className={`doctorInfo ${(selected === EXACT_DOC && chooseDoctor?.id === doctor.id) ? 'selectedDoctor' : ''}`}
            >
              <span>
                <img
                  src={(selected === EXACT_DOC && chooseDoctor?.id === doctor.id)
                    ? iconChecked
                    : doctor?.employee?.photoUrl || oneDoctor}
                  alt="doctor"
                />
              </span>
              <span>{doctor?.employee?.fullName}</span>
            </div>
          )
        })}
        {doctors && !doctors.length && (
          <div className='emptyResult'>
            <img width='21' height='21' src={searchBlack} alt="no-results" />
            <div>{t("nobodyFound")}</div>
          </div>
        )}
      </div>
    </div>
  )
}


export const MedCardToggle = ({
  options,
  required,
  name,
  input: { onChange, value },
  drugsMode
}) => {
  const [selected, setSelected] = useState(drugsMode && value ? value : null)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const createCardRef = useRef()
  const { t } = useTranslation()
  const history = useHistory()
  const isMaximumCards = options?.length > 6 ? true : false

  useEffect(() => {
    onChange(selected)
  }, [selected])

  const handleCreateCard = () => {
    if (!isMaximumCards) {
      history.push('/new-card')
    }
  }

  const parrentPositionY = createCardRef?.current?.getBoundingClientRect().y + 80;
  const parrentPositionX = createCardRef?.current?.getBoundingClientRect().x + 20;

  return (
    <div className='medContainer'>
      {options && options.map((card) => {
        return (
          <div
            key={card.id}
            onClick={() => {
              console.log(1);
              setSelected(card)
            }}
            className={`medLabel ${value?.id === card.id ? 'medSelected' : ''}`}
          >
            <img
              width='50'
              height='50'
              style={{ marginRight: "15px", borderRadius: "50%", width: "50px" }}
              src={value?.id === card.id ? iconChecked : (card.photoUrl ? card.photoUrl : patient_active)}
              alt="card"
            />
            <div className='userInfoContainer'>
              <div
                className={`userTitle ${value?.id === card.id ? 'activeTitle' : ''}`}>
                {`${card.lastName} ${card.firstName}`}
              </div>
              <div className={`userRelation ${value?.id === card.id ? 'activeRelation' : ''}`}>
                {card.relationShip.title}
              </div>
            </div>
          </div>
        )
      })}
      <div
        ref={createCardRef}
        onMouseEnter={() => setIsPopoverOpen(true)}
        onMouseLeave={() => setIsPopoverOpen(false)}
        onClick={handleCreateCard}
        className={`createCard ${isMaximumCards ? 'disabled' : ''}`}
      >
        <img
          className='addCardPicture'
          src={plusIcon}
          alt="добавить медкарту"
        />
        <div className='createCardText'>{t("createMedicalCard")}</div>
      </div>
      {isPopoverOpen && isMaximumCards && (
        <TextPopover
          text={t("maxCardsText")}
          positionX={parrentPositionX}
          positionY={parrentPositionY}
        />
      )}
    </div>
  )
}


export const TypeToggle = ({options, required, name, input:{ onChange , value}}) => {
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    onChange(selected)
  }, [selected])
  
  const forTwo = options && options.length == 2

  return (
    <div className='typeContainer'>
      {options.map((el, i) => {
        return (
          <div
            key={i}
            onClick={() => setSelected(el)}
            className={`typeLabel ${forTwo ? 'forTwo' : ''} ${value.value === el.value ? 'typeSelected' : ''}`}
          >
            <img
              width='45'
              height='45'
              style={{ marginBottom: "10px" }}
              src={value.value === el.value ? el.icons[1] : el.icons[0]}
              alt="type"
            />
            <div
              className={`typeTitle ${value.value === el.value ? 'activeTitle' : ''}`}>
              {el.label}
            </div>
          </div>
        )
      })}
    </div>
  )
}

const getUserInfo = (state) => state.user.userInfo

const totalSelector = createSelector(
  getUserInfo,
  (userInfo) => userInfo
)

const mapStateToProps = (state) => ({
  formValues: getFormValues('consultation')(state),
  user: totalSelector(state),
  userCards: state.user?.userCards,
  items: getSpecializationsSelector(state),
  bookingDetails: getBookingDetails(state),
  loading: getLoading(state),
  fondySrc: getFondySrc(state),
  isNeedToPay: getIsNeesToPay(state),
  subscription: getSubscriptionsInfo(state),
  bookedConsultationId: getBookedConsultationId(state),
  consultationPrice: getCurrentConsultationPrice(state),
  consultationBonuses: getConsultationBonusesSelector(state),
  initialStatuses: getInitialStatusSelector(state),
  randomDoctor: getRandomDoctorSelector(state),
})

const mapDispatchToProps = {
  getConsultationBookingDetails,
  getCurrentUser,
  getAllSpecializations,
  getUserCards,
  changeConsultationType,
  bookConsultation,
  resetIsEmpty,
  getConsultationPrice,
  resetFondyUrl,
  cancelConsultation,
  calculateConsultationBonuses,
  openFishka,
  getInitialSpecializationStatus,
}
const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({form: 'consultation', enableReinitialize: true})
)

export default enhance(ConsultationBooking)
