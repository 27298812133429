import axios from "axios";
import { setHeader, TEST_API } from "../index";

// eslint-disable-next-line import/no-anonymous-default-export
export default {

  sendCode: (credentials) => {
    return axios.post(`${TEST_API}/login/by-sms`, credentials)
      .then(res => res)
  },

  signin: (credentials) => {
    return axios.post(`${TEST_API}/login/confirm-sms`, credentials)
      .then(res => res)
  },

  getCurrentUser: () => {
    let config = {
        method: "GET",
        baseURL: `${TEST_API}/patients/cards`,
        headers: setHeader(),
    };
    return axios(config).then(res => res)
  },

  getProfile: () => {
    let config = {
        method: "GET",
        baseURL: `${TEST_API}/patients`,
        headers: setHeader(),
    };
    return axios(config).then(res => res)
  },

  sendFirebaseToken: (token) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/devices`,
      headers: setHeader(),
      data: JSON.stringify({ token }),
    };

    return axios(config)
      .then((res) => {
        console.log("Firebase token sent successfully");
      })
      .catch((err) => console.error("Send Firebase token Error:", err));
  },

  firebaseLogOut: () => {
    const firebaseToken = localStorage.getItem("sentFirebaseMessagingToken")
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/devices/logout`,
      headers: setHeader(),
      data: {token: firebaseToken},
    };
    return axios(config)
  }
    // getCurrentUser: () => {
        // let config = {
        // method: "GET",
        // baseURL: `${TEST_API}/login/confirm-sms`,
        // headers: setHeader(),
        // };

    //     return axios(config).then((res) => res && res.data);
    // },

//   sendPassLinkOnEmail: (id) => {
//     let config = {
//       method: "POST",
//       baseURL: `${TEST_API}/admin/employees/${id}/password/send`,
//       headers: setHeader(),
//     };

//     return axios(config).then((res) => res && res.data);
//   },

//   setPass: (data) => {
//     let config = {
//       method: "POST",
//       baseURL: `${TEST_API}/admin/employees/password`,
//       // headers: setHeader(),
//       data,
//     };

//     return axios(config).then((res) => res && res.data);
//   },

  // logOut: () => {
  //   let config = {
  //     method: "POST",
  //     baseURL: `${TEST_API}/admin/devices/logout`,
  //     headers: setHeader(),
  //   };

  //   return axios(config).then((res) => res && res.data);
  // },
};