import { createSelector } from "@reduxjs/toolkit";

const defaultBanners = {
  items: [],
  loading: false,
};

const defaultState = { banners: { ...defaultBanners }};

const getBanners = (state = defaultState) =>
  state.banners;

export const getBannersLoading = createSelector(
  [getBanners],
  ({loading}) => loading
);
export const getBannersSelector = createSelector(
  [getBanners],
  ({items}) => items
);