import io from "socket.io-client";
// import { SOCKET_HOST } from "../../views/components/Chat1/connection";
import { addMessage, clearMessages, sendMessage, setConnectionState } from "../chat/chat-actions";
import { getChatHistory, joinRoom, leaveRoom, wsConnect, wsDisconnect } from "./socketActions";
import { socketHandlers } from "./handlers";
import store from "../store";
import { callRtcTools } from "./callTools";

const CHAT_PORT = 8085
const CHAT_HOST = process.env.REACT_APP_SIGNAL_SERVER
const SOCKET_HOST = `${CHAT_HOST}:${CHAT_PORT}`
// const SOCKET_HOST = `http://localhost:8085`

const socketMiddleWare = () => {
  let socket = null;
  let cbListForAuth = [];
  let isAuthenticated = false
  let currentAppointmentId = null
  let callTools = {
    handleAnswerCall: () => { },
    handleRejectCall: () => { },
    handleEndCall: () => { },
    toggleVideo: () => { },
    toggleAudio: () => { },
    handleCall: () => { },
    handleOffer: () => { },
    handleCandidate: () => { },
  }

  const sendCallMessage = (data) => {
    socket.emit("callMessage", {
      appointmentId: currentAppointmentId,
      message: {
        ...data,
      }
    })
  }

  return ({ dispatch }) => next => async action => {
    switch (action.type) {
      case "chat-socket/wsConnect": // CONFIGURE SOCKET
        if (socket) {
          socket.close()
          socket = null
        }
        socket = io.connect(SOCKET_HOST, {
          "reconnection delay": 0,
          "reopen delay": 0,
          "force new connection": true,
          transports: ["websocket"],
        });
        socket.on("connect", () => {
          console.log("CONNECTED TO CHAT SOCKET");
          callTools = callRtcTools({dispatch, socket, sendCallMessage, refs: {...action.payload}})
          socket.emit("auth", { token: localStorage.getItem("token") });
          dispatch(setConnectionState(true))
        });
        socket.on("disconnect", () => {
          console.log("DISCONNECTED FROM SOCKET");
          dispatch(setConnectionState(false))
        })
        socket.on("connect_error", () => {
          console.log("ERROR FROM SOCKET");
          // dispatch(setConnectionState(false))
        })
        socket.on("onAuth", () => {
          isAuthenticated = true
          Object.keys(socketHandlers).forEach((key) => {
            if (key === "onCallMessage") {
              socket.on([key], socketHandlers[key](callTools))
            } else {
              socket.on([key], socketHandlers[key](dispatch));
            }
          });
          console.log("cbListForAuth", cbListForAuth);

          cbListForAuth.forEach((callback) => callback());
          cbListForAuth = [];
        })
        break;
      
      case "chat-socket/getHistory": /// GET CHAT HISTORY
        const {
          userId,
          appointmentId,
          page,
          startDate,
          endDate,
          isSupport,
          typeRoom,
          scrollHandler,
        } = action.payload

        currentAppointmentId = appointmentId

        function getHistory() {
          if (page === 0) {
            if(isSupport) socket.emit("joinRoom", { typeRoom });
            if(!isSupport) socket.emit("joinAppointmentRoom", { typeRoom, appointmentId });
          }
          if (isSupport) {
            socket.emit("getHistoryWithOperator", {
              skip: page,
              startDate,
              endDate,
            })
          } else {
            socket.emit("getHistoryAppointment", {
              appointmentId,
              startDate,
              endDate,
              skip: page,
              limit: 20,
            })
          }
          if (page > 0 && scrollHandler) scrollHandler()
        }
        if (page > 0) {
          getHistory()
        } else {
          cbListForAuth.push(getHistory);
        }
        break;
      
      case "chat-socket/wsDisconnect":
        console.log("WS DISCONNECT");
        if (socket) {
          dispatch(clearMessages())
          socket.emit("chatRoomLeave")
          socket.close()
          socket = null
          cbListForAuth = [];
        }
        break;
      case "chat/sendMessage":
        const { messageBody } = action.payload;
        socket.emit("message", messageBody)
        break;
      case "chat-socket/readStartMessages":
        const {list, messageType} = action.payload
        socket.emit("readMessagesList", { idsList: list, messageType });
        break;
      case "chat/answerCall":
        callTools.handleAnswerCall()
        break;
      case "chat/rejectCall":
        callTools.handleRejectCall({ endByMe: true })
        break;
      case "chat/endCall":
        callTools.handleEndCall({ endByMe: true })
        break;
      case "chat/muteVideo":
        callTools.toggleVideo()
        break;
      case "chat/muteAudio":
        callTools.toggleAudio()
        break;
      default: return next(action)
    }
  }
}

export default socketMiddleWare()
