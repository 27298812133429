import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { getCurrentUser, getUserCards } from '../../../redux/auth/auth-operations';
import previousIcon from '../../../style/icons/Subscription/previousIcon.svg'
import patient_active from "../../../style/icons/Subscription/patient_active.svg";
import './cardInfo.scss'
import { parserForm } from '../../../utils/parserForm'
import { useTranslation } from 'react-i18next';
import { getRelations } from '../../../redux/relations/relations-operations'
import { getAddresses } from '../../../redux/addresses/addresses-operations'
import moment from 'moment';
import { LoadingComponent } from '../ConsultationBooking/ConsultationBooking';
import Button from '../../components/ui/Button/Button';
import CardForm from '../../forms/CardForm/CardForm';
import ConsultationHistory from '../../components/ConsultationHistory/ConsultationHistory';
import { updateCard, uploadCardImage } from '../../../redux/cards/cards-operations';
import { setCardId } from '../../../redux/consultations/consultations-actions';
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import { IconButton, makeStyles } from '@material-ui/core';
import { getCurrentPage, getPageCount } from '../../../redux/consultations/consultations-selectors';
import Pagination from '@material-ui/lab/Pagination';
import { getAllConsultations, getConsultationHistory } from '../../../redux/consultations/consultations-operations';
import DeleteIcon from '@material-ui/icons/Delete';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import ImageEditor from '../../components/ImageEditor/ImageEditor';
import { useParams } from 'react-router-dom';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import { getCardPhotoLoading } from '../../../redux/cards/cards-selectors';
import { getAddressesSelector } from '../../../redux/addresses/addresses-selectors';

const CardInfo = ({
  userCards,
  getUserCards,
  setCardId,
  currentCardId,
  pageCount,
  currentPage,
  getConsultationHistory,
  uploadCardImage,
  getCurrentUser,
  photoLoading,
}) => {
  const [imageModalOpen, setImageModalOpen] = useState(false)
  const [card, setCard] = useState({})
  const [loading, setLoading] = useState(false)
  const [mode, setMode] = useState('data')
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation()
  const history = useHistory()
  const { location: { pathname } } = history
  const splitedPath = pathname.split('/')
  const params = useParams()
  const cardId = +splitedPath[splitedPath.length - 1]
  console.log(cardId);
  const INFO = 'data'
  const HISTORY = 'history'
  const ANALYSIS = 'analysis'
  const FILES = 'files'

  const modes = [
    INFO,
    HISTORY,
    // ANALYSIS,
    // FILES,
  ]
  useEffect(() => {
    getUserCards()
    // .then(() => setLoading(false))
  }, []);

  useEffect(() => {
    setCardId(cardId)
  }, [])

  useEffect(() => {
    setCard(userCards.find(el => el.id === cardId))
  }, [userCards])

  const handleCloseModal = () => {
    setModalOpen(false)
  }
  
  const handleChangePage = (_, page) => {
    getConsultationHistory(cardId, page)
  }

  const handleSendRequest = (file) => {
    uploadCardImage(params.cardId, file)
      .then(() => {
        setImageModalOpen(false)
        getUserCards()
        getCurrentUser()
      })
  }

  return (
    <div className='pageContainer'>
      <div className='cardInfoContainer'>
        <PageTitleComponent
          setImageModalOpen={setImageModalOpen}
          setModalOpen={setModalOpen}
          history={history}
          card={card}
        />
        <CardInfoContent
          setMode={setMode}
          modes={modes}
          mode={mode}
          loading={loading}
          setLoading={setLoading}
          currentCardId={currentCardId}
          card={card}
          pageCount={pageCount}
          currentPage={currentPage}
          handleChangePage={handleChangePage}
        />
        <ModalWindow confirmMode open={modalOpen} handleCloseModal={handleCloseModal}>
          <div className='confirmCardContainer'>
            <div className='confirmText'>Вы уверены, что хотите удалить медкарту?</div>
            <div className='confirmButtons'>
              <Button
                type='button'
                className='btn'
                primary
                text={t("yes")}
              />
              <Button
                onClick={() => setModalOpen(false)}
                type='button'
                className='btn'
                danger
                text={t("cancel")}
              />
            </div>
          </div>
        </ModalWindow>
        <ModalWindow confirmMode open={imageModalOpen} handleCloseModal={handleCloseModal}>
          <ImageEditor
            photoLoading={photoLoading}
            handleSendRequest={handleSendRequest}
            cardId={params.cardId}
            setImageModalOpen={setImageModalOpen}
          />
        </ModalWindow>
      </div>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    height: "40px",
    bottom: "0px",
    marginLeft: "10px",
    width: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    '& .Mui-selected': {
        backgroundColor: '#3288cd',
        color:'white',
       },
    '& > *': {
      // marginTop: theme.spacing(1),
      fontSize: "15px",
      '& ul li button': {
        fontSize: "14px",
        fontWeight: "500",
        '&*': {
          backgroundColor: "#3288cd"
        }
      },
    },
  },
  backButton: {
    position: "absolute",
    top: "10px",
    left: "20px"
  }
}));

const CardInfoContent = ({
  card,
  setLoading,
  currentCardId,
  loading,
  setMode,
  modes,
  mode,
  pageCount,
  currentPage,
  handleChangePage,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <div className='cardContentContainer'>
      <ModeTools
        modes={modes}
        mode={mode}
        setMode={setMode}
      />
      <LoadingComponent loading={loading}>
        {mode === 'data' && (
          <ModeInfo
            card={card}
            setLoading={setLoading}
          />
        )}
        {mode === 'history' && (
          <div className='historyModeContainer'>
            <ConsultationHistory
              setLoading={setLoading}
            />
            <div className={`${classes.root} paginatorContainer`}>
              {pageCount > 0 && (
                <div className={classes.root}>
                  <Pagination
                    classes={{ ul: classes.ul }}
                    count={pageCount}
                    color="primary"
                    page={currentPage}
                    defaultPage={1}
                    size='medium'
                    onChange={handleChangePage}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {mode === 'files' && (
          <div className='emptyList'>{t("empty")}</div>
        )}
        {mode === 'analysis' && (
          <div className='emptyList'>{t("empty")}</div>
        )}
      </LoadingComponent>
    </div>
  )
}


const mapStateToPropsMode = ({ relations, ...state }) => ({
  relations: relations,
  addresses: getAddressesSelector(state),
})

const mapDispatchToPropsMode = {
  getRelations,
  getAddresses,
  updateCard,
  getUserCards
}

const ModeInfo = connect(mapStateToPropsMode, mapDispatchToPropsMode)(({
  getRelations,
  relations,
  getAddresses,
  addresses,
  setLoading,
  updateCard,
  getUserCards,
  card,
}) => {
  const [rel, setRel] = useState([])
  const [adr, setAdr] = useState([])
  const [genderOptions, setGenderOptions] = useState([])
  const [userGender, setUserGender] = useState(null)
  const [userRelation, setUserRelation] = useState(null)
  const [userAddress, setUserAddress] = useState(null)
  const [birthDate, setBirthDate] = useState(null)
  const [birthDateError, setBirthDateError] = useState(false)
  const [showAddressForm, setShowAddressForm] = useState(false)

  const { t } = useTranslation()

  const formSubmit = (e) => {
    if (birthDateError) {
      [...e.target].find(el => el.id === 'input')?.focus()
      return
    }
    e.preventDefault()
    setLoading(true)
    const { ...fields } = parserForm(e)
    console.log(fields);
    const requestBody = {
      patientCard: {
        ...fields,
        address: +fields.address,
        relationShip: +fields.relationShip,
        birthDate: moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        gender: +fields.gender,
      }
    }
    updateCard(card.id, requestBody)
      .then(() => getUserCards())
      .then(() => setLoading(false))
    console.log(requestBody);
  }

  useEffect(() => {
    getRelations()
    getAddresses()
  }, [])

  useEffect(() => {
    setGenderOptions([
      { label: t("maleSex"), value: 0 },
      { label: t("femaleSex"), value: 1 }
    ])
  }, [])

  useEffect(() => {
    if (card && rel) {
      const option = rel.find(el => el.value === card.relationShip.id)
      setUserRelation(option)
    }
  }, [rel, card])

  useEffect(() => {
    if (card && genderOptions) {
      const option = genderOptions.find(el => el.value === card.gender)
      setUserGender(option)
    }
  }, [genderOptions, card])

  useEffect(() => {
    setRel(relations.map(rel => ({ label: rel.title, value: rel.id })))
  }, [relations])

  useEffect(() => {
    setAdr(addresses && addresses.map(adr => {
      return { label: `${t("cityShort")}${adr.city}, ${t("streetShort")}${adr.thoroughfare}, ${t("houseShort")}${adr.houseNumber}, ${t("flatShort")}${adr.flatNumber ?? ''}`, value: adr.id }
    }))
  }, [addresses])

  useEffect(() => {
    if (card?.address && adr) {
      const option = adr.find(el => el.value === card.address.id)
      setUserAddress(option)
    }
  }, [adr, card])

  return (
    <div className='editFormContainer'>
      <CardForm
        setBirthDateError={setBirthDateError}
        formSubmit={formSubmit}
        setShowForm={setShowAddressForm}
        showForm={showAddressForm}
        card={card}
        rel={rel}
        adr={adr}
        buttonText={t("updateMedicalCard")}
        genderOptions={genderOptions}
        userGender={userGender}
        userAddress={userAddress}
        userRelation={userRelation}
        setBirthDate={setBirthDate}
        withNewAddress
      />
    </div>
  )
})



const ModeTools = ({ setMode, mode, modes }) => {
  const {t} = useTranslation()
  return (
    <div className='modeContainer'>
      {modes.map((mod, i) => {
        return (
          <div key={i} onClick={() => setMode(mod)} className={`modeItem ${mode === mod ? 'isActive' : ''}`}>
            {t(mod)}
          </div>
        )
      })}
    </div>
  )
}

const useComponentStyles = makeStyles({
  root: {
    display: "flex",
    marginLeft: "auto",
    width: "auto",
    justifyContent: "space-between",
  }
})
const PageTitleComponent = ({
  history,
  card,
  setModalOpen,
  setImageModalOpen,
}) => {
  const { t } = useTranslation()
  const classes = useComponentStyles()
  return (
    <div className='cardPageTitleContainer'>
      <IconButton
        style={{ marginRight: "15px", color: "#3288cd" }}
        variant='contained'
        color='primary'
        onClick={() => history.push('/clinic')}
        title={t("back")}
      >
        <ArrowBackIosTwoToneIcon />
      </IconButton>
      <div className='imageBox'>
        <img
          width='60'
          height='60'
          src={card?.photoUrl || patient_active}
          preload='auto'
          alt="cardPhoto"
        />
      </div>
      <div className='cardInfoTextBox'>
        <div className='cardInfoTitle'>{`${t("Medcard")}: ${card?.lastName || ''} ${card?.firstName || ''}`}</div>
        <div className='cardRelationTitle'>{card?.relationShip?.title}</div>
      </div>
      <div className={classes.root}>
        <IconButton
          variant='contained'
          color='primary'
          onClick={() => setImageModalOpen(true)}
          title={card?.photoUrl ? t("changePhoto") : t("AddPhoto")}
        >
          {card?.photoUrl ? (
            <FlipCameraIosIcon style={{ color: "#3288cd", width: "30px", height: "30px" }}/>
          ) : (
              <AddAPhotoIcon style={{ color: "#3288cd", width: "30px", height: "30px" }} />
          )}
        </IconButton>
        {/* <IconButton
          variant='contained'
          color='secondary'
          onClick={() => setModalOpen(true)}
          title={t("deleteCard")}
        >
          <DeleteIcon style={{ color: "#3288cd", width: "30px", height: "30px" }} />
        </IconButton> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userCards: state?.user?.userCards,
  currentCardId: state?.consultations?.currentCardId,
  currentPage: getCurrentPage(state),
  pageCount: getPageCount(state),
  photoLoading: getCardPhotoLoading(state)
})

const mapDispatchToProps = {
  getUserCards,
  setCardId,
  getConsultationHistory,
  uploadCardImage,
  getCurrentUser,
}
export default connect(mapStateToProps, mapDispatchToProps)(CardInfo);
