import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import previousIcon from '../../../style/icons/Subscription/previousIcon.svg'
import patient_active from "../../../style/icons/Subscription/patient_active.svg";
import { LoadingComponent } from '../../pages/ConsultationBooking/ConsultationBooking';
import { checkIfIsPaid, deleteConsultationDataIfPaid, getAllConsultations, getAnamnesis, getBookedConsultationData, getConsultationHistory, getConsultationInfo, repayConsultation } from '../../../redux/consultations/consultations-operations';
import {getConsultationTime, getMethodTitle, getMethodImage} from '../../../utils/helpers'
import './consultationHistory.scss'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getAllConsultationInfo, getAnamnesisList, getAnamnezLoading, getBookedConsultationId, getBySearchSelector, getConsultationsList, getCurrentCardId, getDrugs, getIsAllDrugsAvailable, getIsEmptySearch, getLoading, getRepaymentInfo } from '../../../redux/consultations/consultations-selectors';
import { addNotification } from '../../../redux/popup/popup-actions';
import {v4} from 'uuid'
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import BookingSuccessComponent from '../../components/BookingSuccessComponent/BookingSuccessComponent';
import { fondySrc } from '../../../redux/consultations/consultations-reducers';
import Button from '../ui/Button/Button';
import activeDocIcon from "../../../style/icons/Doc/doc-blue.svg";
import checked from '../../../style/icons/Analyses/check.svg'
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { changeDrugQuantity } from '../../../redux/consultations/consultations-actions';
import { setSelected } from '../../../redux/drugs/drugs-actions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import { useStyles } from './styles';
import { isBackgroundCallNeeded } from '../../../redux/chat/chat-actions';
import "moment/locale/uk";
import "moment/locale/ru";
import moment from 'moment';
const drugsNumber = Array(100).fill(0)

const ConsultationHistory = ({
  consultations,
  getAllConsultations,
  getConsultationHistory,
  loading,
  currentCardId,
  allMode,
  anamnezLoading,
  addNotification,
  getConsultationInfo,
  allConsultationInfo,
  drugs,
  changeDrugQuantity,
  isAllDrugsUnavailable,
  setSelected,
  repaymentUrl,
  repayConsultation,
  consultationsBySearch,
  isActiveCall,
  isEmptySearch,
}) => {
  const [payLoading, setPayLoading] = useState(false)
  const [consultationIds, setConsulationIds] = useState([])
  const [payOpen, setPayOpen] = useState(false)
  const [isWantToPay, setIsWantToPay] = useState(false)
  const [isPayed, setIsPayed] = useState(false)
  const [fondyUrl, setFondyUrl] = useState(null)
  const [currentId, setCurrentId] = useState(null)
  const [drugList, setDrugList] = useState(null)
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const lang = localStorage.getItem("i18nextLng")
  useEffect(() => {
    if (allMode) return
    getConsultationHistory(currentCardId)
  }, [currentCardId])
  console.log('consultattions', consultations);

  useEffect(() => {
    if (!allMode) return
    getAllConsultations()
    moment.locale(lang)
  }, [])
  
  useEffect(() => {
    if (currentId) {
      getConsultationInfo(currentId)
      // getAnamnesis(currentId)
    }
  }, [currentId])


  const handleChangeSelect = (event, id) => {
    changeDrugQuantity({
      id: id,
      quantity: +event.target.value
    })
  }

  const handleScroll = (e) => {
    setTimeout(() => {
      e.target.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }, 700)
  }

  const handleClick = (e, item) => {
    if (allMode && item.status === 'in_process') {
      localStorage.setItem('currentConsultationInfo', JSON.stringify(item))
      history.push(`/appointment?id=${item.id}&doctorId=${item.doctor.id}&doctorSpecialtyId=${item.specialty.id}`)
      return
    }
    if (allMode && (item.status === 'new' || item.status === 'filled')) {
      if (!item.isPayed && item.payment) {
        setPayOpen(true)
        setFondyUrl(item.payment.paymentUrl)
        setCurrentId(item.id)
        return
      } else {
        localStorage.setItem('currentConsultationInfo', JSON.stringify(item))
        history.push(`/appointment?id=${item.id}&doctorId=${item.doctor.id}&doctorSpecialtyId=${item.specialty.id}`)
        return
      }
    }
    if (allMode && item.status === 'cancelled') {
      addNotification({
        id: v4(),
        type: 'ERROR',
        message: t("canceledConsultationMessage")
      })
      return
    }
    if (!consultationIds.find(el => el === item.id)) {
      handleScroll(e)
    }
    if (consultationIds.find(el => el === item.id)) {
      setConsulationIds(prev => prev.filter(el => el !== item.id))
      setCurrentId(null)
    } else {
      setConsulationIds(prev => [...prev, item.id])
      setCurrentId(item.id)
    }
  }

  window.onmessage = function (event) {
    if (event.origin === "http://dobrodoc.com" || event.origin.includes("web.dobrodoc")) {
      setIsPayed(true)
    }
  };

  const handleCloseModal = () => {
    setFondyUrl(null)
    setPayOpen(false)
    setIsPayed(false)
    setIsWantToPay(false)
  }

  const generateInfoClassName = () => {
    if (!allConsultationInfo?.complaintsText || !allConsultationInfo?.conclusionText) {
      return 'noContent'
    }
    return ''
  }

  const handleBookDrugs = (item) => {
    Object.keys(drugs).forEach((id) => {
      if (drugs[id].status === 'unavailable') return
      const item = drugs[id]
      setSelected({
        ...item
      })
    })
    history.push(`/drugs/${currentId}/${item.patientCard.id}`)
  }

  const handleRepay = () => {
    setIsWantToPay(true);
    setPayLoading(true);
    repayConsultation(currentId)
      .then(() => setPayLoading(false))
  }

  const isSearching = consultationsBySearch.length > 0;
  return (
    <div className='historyContainer'>
      <LoadingComponent loading={loading}>
        {!consultations?.length && (
          <div className='emptyCase'>{t('emptyList')}</div>
        )}
        {isEmptySearch && (
          <div className='emptyCase'>{t('emptySearchResults')}</div>
        )}
        {!isEmptySearch && (isSearching ? consultationsBySearch : consultations).map(item => {
          return (<>
            <div
              key={item.id}
              onClick={(e) => handleClick(e, item)}
              className='historyItem'
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  className='doctorPhoto'
                  src={item.doctor?.employee?.photoUrl || activeDocIcon}
                  alt=""
                />
                <div className="consultInfoContainer">
                  <div className='doctorName'>{item.doctor.fullName || item.doctor.employee.fullName}</div>
                  <div className='specialtyTitle'>{item.specialty.title}</div>
                </div>
              </div>
              <div className="consultInfoContainer">
                <div className='dateTitle'>{t("dateAndTime")}</div>
                <div className='consultationDate'>{getConsultationTime(item.start)}</div>
              </div>
              {allMode && (
                <div className="consultStatusContainer">
                  <div className='statustitle'>{t("status")}</div>
                  <div className={`consultationStatus ${item.status}`}>{t(item.status)}</div>
                </div>
              )}
              <div className="consultInfoContainer type">
                <div className='consultationType'>{t("consultationType")}</div>
                <div className='consultationMethod'>{t(getMethodTitle(item.method))}</div>
              </div>
              <img
                className='consultationImage'
                src={getMethodImage(item.method)}
                alt="consultationMethod"
              />
              {item.status === 'closed' && (
                <img
                  preload='auto'
                  className={`showMoreImg ${(consultationIds.find(el => el === item.id)) ? 'rotate' : ''}`}
                  style={{ marginLeft: "auto" }}
                  src={previousIcon}
                  alt="showMore"
                />
              )}
              {item.isNeedToPay && item.status === 'new' && item.type == 0 && (
                <MonetizationOnIcon className={classes.paymentIcon} />
              )}
              {!item.isNeedToPay && item.status === 'new' && item.type == 0 && (
                <CheckCircleIcon className={classes.paymentDoneIcon} />
              )}
            </div>
            <>
              {!!consultationIds.find(el => el === item.id) && (<>
                <div className={`consultInfoBox ${generateInfoClassName()}`}>
                  <LoadingComponent width={50} height={50} loading={anamnezLoading && currentId === item.id}>
                    {generateInfoClassName() === 'noContent' && (
                      <div className="noContentBox">{t("noContent")}</div>
                    )}
                    <div className="complaintsInfo">
                      <div className="complaintsTitle">Жалобы</div>
                      <div className="complaintsText">{allConsultationInfo?.complaintsText}</div>
                    </div>
                    <div className="conclusionInfo">
                      <div className="conclusionTitle">Заключение</div>
                      <div className="conclusionText">{allConsultationInfo?.conclusionText}</div>
                    </div>
                    {allConsultationInfo?.diagnostic?.length > 0 && (
                      <div className="diagnosticBox">
                        <div className='diagnosticTitle'>{t("examinations")}</div>
                        <div className="diagnosticList">
                          {allConsultationInfo.diagnostic.map(item => {
                            return (
                              <div key={item.id} className='diagnosticItem'>{item.title}</div>
                            )
                          })}
                        </div>
                      </div>
                    )}
                    {allConsultationInfo?.disease?.length > 0 && (
                      <div className="diseaseBox">
                        <div className='diseaseTitle'>{t("presumptiveDiagnosis")}</div>
                        <div className="diseaseList">
                          {allConsultationInfo.disease.map(item => {
                            return (
                              <div key={item.id} className='diseaseItem'>{item.title}</div>
                            )
                          })}
                        </div>
                      </div>
                    )}
                    {allConsultationInfo?.analysis?.length > 0 && (
                      <div className='analysisItemsBox'>
                        <div className='analysisTitle'>{t("analysises")}</div>
                        <div className='analysisItemsContainer'>
                          <div className='analysisItem'>
                            <div className="itemInfo center column">{t("analysisName")}</div>
                            <div className="itemInfo column">{t("analysisTime")}</div>
                            <div className="itemInfo column">{t("analysisPrice")}</div>
                          </div>
                          {allConsultationInfo.analysis.map(item => {
                            return (
                              <div key={item.id} className='analysisItem'>
                                <div className="itemInfo">{item.title}</div>
                                <div className="itemInfo">{item.daysMin}-{item.daysMax}</div>
                                <div className="itemInfo">{item.price}</div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )}
                    {drugs && (<>
                      <div className="drugsContainer">
                        <div className="drugListTitle">{t("drugs")}</div>
                        <div className='drugsListContainer'>
                          <div className='analysisTableNames'>
                            <div className='selectedCounter'>{allConsultationInfo?.drugs?.length}</div>
                            <div className='tableName toCenter'>{t("analysisName")}</div>
                            <div className='tableName toRight'>{t('pharmacyCount')}</div>
                            <div className='tableName'>{t("analysisPrice")}</div>
                          </div>
                          <div className='analysisList'>
                            {Object.keys(drugs).map((item, i) => {
                              return (
                                <div key={i} className={`analysisItem ${drugs[item].status === 'unavailable' ? 'unavailable' : ''}`}>
                                  {drugs[item].status === 'unavailable' && (
                                    <div className='noItems'>{t("notAvailable")}</div>
                                  )}
                                  <div style={{ display: "flex" }}>
                                    <img className='checkBox' src={checked} alt="drugs" />
                                    <div className='analysTitle real'>{drugs[item].title}</div>
                                  </div>
                                  <div className='selectContainer'>
                                    <Select
                                      value={drugs[item].quantity}
                                      onChange={(e) => handleChangeSelect(e, drugs[item].id)}
                                      displayEmpty
                                      disabled={drugs[item].status === 'unavailable'}
                                      inputProps={{ 'aria-label': 'Without label' }}
                                      disableUnderline
                                      defaultValue={1}
                                    >
                                      {drugsNumber.map((_, i) => {
                                        return (<MenuItem key={i} className={classes.selectItem} value={i + 1}>{i + 1}</MenuItem>)
                                      })}
                                    </Select>
                                  </div>
                                  <div className='analysTitle toCenter'>{drugs[item].price}</div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        <Button
                          onClick={() => handleBookDrugs(item)}
                          disabled={isAllDrugsUnavailable}
                          className='btn'
                          text={t('book')}
                        />
                      </div>
                    </>)}
                  </LoadingComponent>
                </div>
              </>)}
            </>
          </>)
        })}
      </LoadingComponent>
      <ModalWindow
        open={payOpen}
        handleCloseModal={handleCloseModal}
        withPayment={isWantToPay}
      >
        {!payLoading && !isPayed && !isWantToPay && (
          <div className='offerBox'>
            <div className='offer'>{t("paymentDesc")}</div>
            <div className="btnContainer">
              <Button onClick={handleRepay} className='btn' primary type='button' text={t("yes")} />
              <Button onClick={() => setPayOpen(false)} className='btn' primary type='button' text={t("no")} />
            </div>
          </div>
        )}
        {payLoading && (
          <LoadingComponent loading={true}/>
        )}
        {repaymentUrl && isWantToPay && !isPayed && (
          <iframe
            onLoad={(e) => setPayLoading(false)}
            src={repaymentUrl}
            frameBorder="0"
            className='fondyPayment'
            title='fondySystem'
          ></iframe>
        )}
        {isPayed && (
          <BookingSuccessComponent
            text={'Консультация успешно оплачена'}
            onClick={handleCloseModal}
          />
        )}
      </ModalWindow>
    </div>
  )
}

const mapStateToProps = (state) => ({
  consultations: getConsultationsList(state),
  anamnesis: getAnamnesisList(state),
  currentCardId: getCurrentCardId(state),
  loading: getLoading(state),
  anamnezLoading: getAnamnezLoading(state),
  bookedConsultationId: getBookedConsultationId(state),
  allConsultationInfo: getAllConsultationInfo(state),
  drugs: getDrugs(state),
  isAllDrugsUnavailable: getIsAllDrugsAvailable(state),
  repaymentUrl: getRepaymentInfo(state),
  consultationsBySearch: getBySearchSelector(state),
  isActiveCall: state.chat.isPeerConnected,
  // isEmptySearch: getIsEmptySearch(state),
})

const mapDispatchToProps = {
  getAllConsultations,
  getAnamnesis,
  getConsultationHistory,
  addNotification,
  getConsultationInfo,
  changeDrugQuantity,
  setSelected,
  repayConsultation,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationHistory)