import {
    registerSuccess,
    registerError,
    loginSuccess,
    loginError,
    getCurrentUserSuccess,
    getCurrentUserError,
    sendCodeSuccess,
    getUserCardsSuccess,
    logOutSuccess, logOutError,
    getRequest,
    getProfileSuccess,
    getUserRequest,
    logoutRequest
} from './auth-actions'
import api from '../../api'
import axios from 'axios'

const token = {
    setToken(token, refreshToken) {
        localStorage.setItem('token', token)
        localStorage.setItem('refreshToken', refreshToken)
    },
    unSetToken() {
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')
    }
}
export const sendCode = phone => async dispatch => {
    try {
        await api.user.sendCode({
            phone,
        })
        dispatch(sendCodeSuccess())
    } catch (e) {
        console.log("error");
    }
}

export const register = credentials => async dispatch => {
    try {
        const { data } = await axios.post('users/signup', credentials)
        token.setToken(data.token)
        dispatch(registerSuccess(data))
        
    } catch(error) {
        dispatch(registerError(error.message))
    }
}

export const login = credentials => async dispatch => {
    try {
        const { data } = await api.user.signin(credentials)
        token.setToken(data.token, data.refresh_token)
        dispatch(loginSuccess(data))
        dispatch(getCurrentUser())
    } catch (error) {
        dispatch(loginError(error.message))
    }  
}


export const logOut = () => async dispatch => {
    try {
        dispatch(logoutRequest())
        await api.user.firebaseLogOut()
        token.unSetToken()
        const lang = localStorage.getItem("lang")
        localStorage.clear()
        localStorage.setItem("lang", lang)
        window.location.reload()
    } catch(error) {
        dispatch(logOutError(error.message))
    }
}

export const getCurrentUser = () => async (dispatch) => {
    dispatch(getUserRequest())
    if (!localStorage.getItem('token')) {
        return
    }
    try {
        const { data } = await api.user.getCurrentUser()
        dispatch(getCurrentUserSuccess(data))
    } catch(error) {
        dispatch(getCurrentUserError(error.message))
    }
}

export const getUserCards = () => async dispatch => {
    dispatch(getRequest())
    try {
        // debugger
        const { data } = await api.user.getCurrentUser()
        dispatch(getUserCardsSuccess(data))
    } catch (error) {
        console.log(error);
        // dispatch(getUserCardsSuccess(error.message))
    }
}

export const getProfile = () => async dispatch => {
    try {
        const {data} = await api.user.getProfile()
        dispatch(getProfileSuccess(data))
    } catch (error) {
        console.log(error.message);
    }  
}