import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import successIcon from '../../../style/icons/Success/successIcon.svg'
import { LoadingComponent } from '../../pages/ConsultationBooking/ConsultationBooking'
import ErrorIcon from "@material-ui/icons/Error"
import Button from '../ui/Button/Button'
import './bookingSuccess.scss'

const BookingSuccessComponent = ({ text, loading, onClick, error }) => {
  const history = useHistory()
  const {t} = useTranslation()
  return (
    <div className='bookingSuccessContainer'>
      <LoadingComponent loading={loading}>
        <div className='bookingSuccessContent'>
          {error && (
            <ErrorIcon style={{color: "red", width: "50px", height: "50px", opacity: "0.8"}}/>
          )}
          {!error && (
            <img src={successIcon} alt="successIcon" />
          )}
          <div className='bookingText'>{!error ? text : t("bookingError")}</div>
        </div>
        <div className='analysisStepButton'>
          <Button
            onClick={() => onClick ? onClick() : history.push('/clinic')}
            type='button'
            className='btn'
            text={!error ? t("goodButton") : t("bookAgain")}
          />
        </div>
      </LoadingComponent>
    </div>
  )
}

export default BookingSuccessComponent;