import { createAction } from "@reduxjs/toolkit";

export const wsConnect = createAction("chat-socket/wsConnect")
export const wsDisconnect = createAction("chat-socket/wsDisconnect")
export const readMessages = createAction("chat-socket/readMessages")
export const sendMessage = createAction("chat-socket/sendMessage")
export const setAuthorized = createAction("chat-socket/setAuthorized")
export const readChatMessages = createAction("chat-socket/readAllMessages")
export const readStartMessages = createAction("chat-socket/readStartMessages")
export const getChatHistory = createAction("chat-socket/getHistory")
export const joinRoom = createAction("chat-socket/joinRoom")
export const leaveRoom = createAction("chat-socket/leaveRoom")
