import { useEffect, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import previousIcon from '../../../../style/icons/Subscription/previousIcon.svg'
import patientActive from '../../../../style/icons/Subscription/patient_active.svg'
import { getUserCards } from "../../../../redux/auth/auth-operations"
import '../navbar.scss'
import { NavLink, useHistory } from "react-router-dom"
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { IconButton } from '@material-ui/core';
import { LoadingComponent } from "../../../pages/ConsultationBooking/ConsultationBooking"

const SubscriptionCards = ({
  setCardListOpen,
  setCardId,
  setOpenMenu,
  t,
}) => {
  const dispatch = useDispatch()
  const userCards = useSelector(state => state?.user?.userCards)
  const loading = useSelector(({ user }) => user?.loading)
  const history = useHistory()

  useEffect(() => {
    dispatch(getUserCards())
  }, [])
  
  return (
    <div className='medcardsBox'>
      <div className='listTool'>
        <IconButton
          style={{ width: "20px", height: "20px", color: "#3288cd" }}
          variant='contained'
          color='primary'
          onClick={() => setCardListOpen(false)}
          title={t("back")}
        >
          <ChevronLeftIcon />
        </IconButton>
        <div className='title'>{t("selectMedicalCard")}</div>
      </div>
      <div className="cardBox">
        <LoadingComponent loading={loading} width={70} height={70}>
          {
            userCards && userCards.map(card => {
              return (
                <NavLink key={card.id} className='cardHref' to={`/subscriptions/${card.id}`}>
                  <div
                    onClick={() => {
                      // history.push(`subscriptions/${card.id}`)
                      setCardListOpen(false)
                      setOpenMenu(false)
                    }}
                    className='userCard'>
                    <div
                      style={{
                        backgroundImage: `url(${card.photoUrl || patientActive})`,
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        width: "50px"
                      }}
                      className='subscriptionCardImage'
                    ></div>
                    {/* <img width='50' height='50' src={card.photoUrl || patientActive} alt="userCardPhoto" /> */}
                    <div className='infoContainer'>
                      <div className='userName'>{`${card.lastName} ${card.firstName}`}</div>
                      <div className="userRelation">{card.relationShip.title}</div>
                    </div>
                  </div>
                </NavLink>
              )
            })
          }
        </LoadingComponent>
      </div>
    </div>
  )
}

export default SubscriptionCards
