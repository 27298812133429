import React, { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import './searchResults.scss'

const SearchResults = ({
  symptoms,
  handleSearch,
  value,
}) => {
  const [currentValue, setCurrentValue] = useState(null)
  const [selected, setSelected] = useState(0)
  const history = useHistory()
  const listRef = useRef(null)
  const { t } = useTranslation()

  useEffect(() => {
    window.addEventListener('click', handleOnMove)
    return () => window.removeEventListener('click', handleOnMove)
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyNavigation)
    return () => window.removeEventListener('keydown', handleKeyNavigation)
  }, [selected, listRef, symptoms])

  useEffect(() => {
    if (value) {
      console.log(/^[А-Я-A-Z]/.test(value));
      if (/^[А-Я-A-Z]/.test(value) && currentValue) {
        setCurrentValue(null)
        return
      }
      setCurrentValue(value)
    } else {
      setCurrentValue(null)
    }
  }, [value])

  const handleSelect = (title) => {
    handleSearch(title)
  }

  const handleOnMove = (e) => {
    if (e.target.id !== "searchBoxId") {
      setCurrentValue(null)
    }
  }

  const moveDown = () => {
    const isExist = symptoms.length > selected
    console.log(isExist);
    if(!isExist) return
    setSelected(prev => prev + 1)
    if (listRef?.current) {
      listRef.current.scrollTo({
        top: listRef.current.scrollTop + 44,
        behavior: 'smooth',
      })
    }
  }

  const moveUp = () => {
    const isExist = selected != 1
    if(!isExist) return
    setSelected(prev => prev - 1)
    if (listRef?.current) {
      listRef.current.scrollTo({
        top: listRef.current.scrollTop - 44,
        behavior: 'smooth',
      })
    }
  }

  const handleKeyNavigation = (e) => {
    console.log(e.keyCode);
    if (!selected) {
      setSelected(1)
    }
    if (selected) {
      switch (e.keyCode) {
        case 38:
          moveUp()
          break;
        case 40:
          moveDown()
          break;
        case 13:
          const sym = symptoms.find((_, i) => i + 1 == selected)
          if(!sym) return
          handleSelect(sym.title)
          history.push('/clinic')
        default: return
      }
    }
  }


  
  return (
    <div id='searchBoxId' className={`searchContainer`}>
      <div className='searchPlaceHolder'>{t("frequentRequests")}</div>
      <div ref={listRef} className='searchItemContainer'>
      {symptoms && symptoms.length != 0 && symptoms.map((sym, i) => {
          return (
            <div
              key={sym.id}
              onClick={() => {
                handleSelect(sym.title)
                history.push('/clinic')
              }}
              className={`searchItem ${selected == i + 1 ? 'selected' : ''}`}
            >
            <div
              className='searchTitle'>
              {sym.title}
            </div>
            </div>
          )
        })}  
      </div>
    </div>
  )
}
export default SearchResults;