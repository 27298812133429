import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getCurrentConsultationPrice } from '../../../redux/consultations/consultations-selectors';
import { getPriceHome, getPriceHospital, getPriceLoading, getPriceOnline } from '../../../redux/consultations/consultations-selectors';
import { getIsRegistered } from '../../../redux/fishka/fishka-selectors';
import { getSubscriptionsInfo } from '../../../redux/subscription/subscription-selectors';
import { LoadingComponent } from '../../pages/ConsultationBooking/ConsultationBooking';
import './priceComponent.scss'

const PriceComponent = ({
  consultationPrice,
  loading,
  analysisMode,
  analysisPrice,
  isRegistered,
  isConfirmationStep = false,
  isFishkaNeeded,
}) => {
  const { t } = useTranslation()
  return (
    <div className='priceContainer'>
      <div className={`priceBox ${!isRegistered || !isConfirmationStep ? 'small' : ''}`}>
        <LoadingComponent loading={loading} width={25} height={25}>
          {analysisMode && (<>
            <div className='onlyPriceBox'>
              <div className='priceTitle'>{t("cost")}</div>
              <div className='price'>{analysisPrice?.toFixed(2) || (0).toFixed(2)} &#8372;</div>
            </div>
          </>)}
          {!analysisMode && (<>
            <div className='onlyPriceBox'>
              <div className='priceTitle'>{t("cost")}</div>
              <div className='price'>{consultationPrice?.price?.toFixed(2)} &#8372;</div>
            </div>
            {isRegistered && isConfirmationStep && isFishkaNeeded && (
              <div className='fishkaPriceBox'>
                <div className='priceTitle diff'>{t("fishkaPercent")}</div>
                <div className='price diff'>{Number(consultationPrice?.price / 100 * 10)?.toFixed(2)}</div>
              </div>
            )}
          </>)}
        </LoadingComponent>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  subscription: getSubscriptionsInfo(state),
  loading: getPriceLoading(state),
  consultationPrice: getCurrentConsultationPrice(state),
  isRegistered: getIsRegistered(state),
})

export default connect(mapStateToProps)(PriceComponent)
