import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useParams } from 'react-router';
import { getBanners } from '../../../redux/banners/banners-operations';
import { getBannersLoading, getBannersSelector } from '../../../redux/banners/banners-selectors';
import './registration.scss'
import useStyles from '../../components/FishkaMain/materialStyles'
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import CardForm from '../../forms/CardForm/CardForm';
import { getRelations } from '../../../redux/relations/relations-operations';
import { parserForm } from '../../../utils/parserForm';
import moment from 'moment';
import { createCard } from '../../../redux/cards/cards-operations';
import { getCardsLoading } from '../../../redux/cards/cards-selectors';


const Registration = () => {
  const [showForm, setShowForm] = React.useState(false)
  const [genderOptions, setGenderOptions] = React.useState(null)
  const [birthDateError, setBirthDateError] = React.useState(false)
  const [birthDate, setBirthDate] = React.useState(null)
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const banners = useSelector(getBannersSelector)
  const bannersLoading = useSelector(getBannersLoading)
  const cardLoading = useSelector(getCardsLoading)
  const relations = useSelector(state => state.relations)
  const params = useParams()
  const splideRef = useRef()
  
  
  React.useEffect(() => {
    setGenderOptions([
      { label: t("maleSex"), value: 0 },
      { label: t("femaleSex"), value: 1 }
    ])
  }, [])


  React.useEffect(() => {
    dispatch(getBanners())
    dispatch(getRelations())
  }, [])

  const handleNext = () => {
    const isLastIndex = splideRef.current.splide._i === banners.length - 1;
    splideRef.current.splide.Components.Controller.go("+1")
    if (isLastIndex) {
      setShowForm(true)
    }
  }

  const formSubmit = (e) => {
    const { ...fields } = parserForm(e)
    const requestBody = {
      patientCard: {
        ...fields,
        birthDate: moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        relationShip: 1,
        gender: +fields.gender,
        // address: +fields.address,
      }
    }
    dispatch(createCard(requestBody, null, true))
  }

  return (
    <div className='registrationPageContainer'>
      <div className="registrationBox">
        <div className="registerTitle">{t("Registration")}</div>
        {!showForm && (
          <div className="bannerBox">
          <Splide
            ref={splideRef}
            options={{
              type: 'slide',
              gap: '2em',
              pauseOnHover: false,
              resetProgress: false,
              arrows: false,
              direction: 'ltr',
              focus: 'center',
              height: "600px",
              updateOnMove: true,
              perPage: 1,
              autoHeight: true,
              perMove: 1,
              padding: {
                top: '0.5rem',
                bottom: '40px',
              },
              keyboard: true,
              classes: {
                page: 'splide__pagination__page your-class-page',
                pagination: "splide__pagination class-pagination"
              }
            }}
          >
            {banners.map((item) => (
              <SplideSlide key={item.id}>
                <BannerItem item={item}/>
              </SplideSlide>
            ))}
          </Splide>
        </div>
        )}
        {showForm && (
          <div className='registerFormContainer'>
            <CardForm
              fishkaMode
              isAuthorized={false}
              buttonLoading={cardLoading}
              formSubmit={formSubmit}
              setBirthDate={setBirthDate}
              setBirthDateError={setBirthDateError}
              genderOptions={genderOptions}
              registerMode
            />
          </div>
        )}
        {!showForm && (
          <div className='nextButtonBox'>
            <Button
              fullWidth
              variant='contained'
              className={classes.button}
              onClick={handleNext}
              loading={true}
            >{t("Next")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}


const BannerItem = ({item}) => {
  return (
    <div className="bannerItem">
      <img width='450' height='450' className='bannerImage' src={item.srcUrl} alt="banner-image" />
      <div className="bannerText">{item.text}</div>
    </div>
  )
}

export default Registration;
